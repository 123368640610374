import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    IDropdownOption,
    IStackStyles,
    Modal,
    PivotItem,
    PrimaryButton,
    TextField,
    Toggle,
} from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { Action } from "redux";
import { appInsights } from "src/AppInsights";
import { localize } from "src/l10n";
import { getLanguageOptions } from "src/l10n/localize";
import { setConfirmPopup } from "src/popups/actions";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { ContentVersionsList } from "src/spintr/components";
import InformationFeedEntry from "src/spintr/components/InformationFeed/InformationFeedEntry";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import { Autosaver, Breadcrumbs, ContentImageViewerAndSelector, DivWithHoverActions, EditableContentSections, Label, Loader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { Style } from "src/ui/helpers";
import { autosave, isAnythingDirty, scrollToTop, validateRequiredTextField } from "src/utils";
import NewsArticleView from "./NewsArticleView";
import PopupHeader from "src/ui/components/PopupHeader";
import CenteredAddButton from "src/ui/components/CenteredAddButton/CenteredAddButton";
import FilePicker from "src/spintr/components/FilePicker";
import SimpleFile from "src/ui/components/SimpleFile/SimpleFile";
import { FieldCompletion } from "src/assistant";
import { fetchMenu } from "src/sidebar/utils";
import SplitForm from "src/ui/components/SplitForm/SplitForm";
import { extractHtmlFromUberContent } from "src/ui/components/UberContent";
import { AxiosResponse } from "axios";

import "./NewsEditView.scss";

interface IPageRouteParams {
    path: string;
    id: string;
}

interface Props extends RouteChildrenProps<IPageRouteParams> {
    currentUser: IActiveUserProfile;
    match: any;
    instance: any;
    isAdmin: any;
    isEditor: any;
    currentUserId: number;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    isSmallViewMode?: boolean;
    targetToCompany?: boolean;
    disableCommentsByDefault?: boolean;
    enableBuilder?:     boolean | undefined;
    dispatch: (action: Action) => void;
}

interface State {
    autosaveId: number;
    isAutosaving: boolean;
    lastAutosave?: Date;
    isLoading: boolean;
    article: Spintr.INewsArticle;
    saveError: string[];
    saveSuccess: boolean;
    showImageSelector: boolean;
    showModal: boolean;
    showInfoDialog: boolean;
    infoDialogMessage: string;
    differentDisplayFormatAfterAttached: boolean;
    selectedTemplateAfterAttached: object;
    showRejectModal: boolean;
    rejectMessage: string;
    hideDialog: boolean;
    dialogMessage: string;
    sendAsEmail: boolean;
    showPreviewImageSelector?: boolean;
    enableFieldValidation?: boolean;
    displayPreamble?: boolean;
    uberCategories: any[];
    displayPreview: boolean;
    isFilePickerOpen: boolean;
}

const stackStyles: IStackStyles = {
    root: {
        paddingTop: Style.getSpacing(4),
    },
};

class NewsEditView extends Component<Props, State> {
    private referenceModel: Spintr.INewsArticle;
    public static contextType = ThemeContext;
    private isNew = !!!this.props.match.params.slug;
    private contentReview = this.props.match.params.versionId !== undefined;
    private adminNewsEditView = window.location.href.includes("admin");

    templates = [
        { text: localize("Liten"), key: 1 },
        { text: localize("Mellan"), key: 2 },
        { text: localize("Stor"), key: 3 },
        { text: localize("UtanBild"), key: 4 },
    ];

    constructor(props: Props) {
        super(props);

        let targets: any[] | undefined = undefined;
        if (props.targetToCompany && props.currentUser.department?.office?.company) {
            const company = props.currentUser.department.office.company;

            targets = [{
                id: company.id,
                key: company.id,
                name: company.name,
                subText: localize("Foretag"),
            }];
        }

        this.state = {
            autosaveId: 0,
            isAutosaving: false,
            isLoading: true,
            article: {
                id: 0,
                name: "",
                isNew: true,
                introTitle: "",
                preamble: "",
                tags: [],
                publishStartDate: new Date(),
                publisherId: props.currentUser.id,
                publisherName: props.currentUser.name,
                publisher: {
                    id: props.currentUser.id,
                    type: SpintrTypes.UberType.User,
                    name: props.currentUser.name,
                    typeName: "Person",
                },
                allowComments: !this.props.disableCommentsByDefault,
                visibleToEveryone: true,
                attachedBackground: 2,
                showPublisher: true,
                template: SpintrTypes.InformationFeedTemplate.Large,
                restrictToTargets: true,
                prioLevel: 0,
                targets,
            } as Spintr.INewsArticle,
            saveError: [],
            saveSuccess: false,
            showImageSelector: false,
            showModal: false,
            showInfoDialog: false,
            infoDialogMessage: "",
            differentDisplayFormatAfterAttached: true,
            selectedTemplateAfterAttached: this.templates[0],
            showRejectModal: false,
            rejectMessage: "",
            hideDialog: true,
            dialogMessage: "",
            sendAsEmail: false,
            displayPreamble: false,
            uberCategories: [],
            displayPreview: false,
            isFilePickerOpen: false,
        };
        this.referenceModel = { ...this.state.article };

        this.onContentChanged = this.onContentChanged.bind(this);
        this.onAutosave = this.onAutosave.bind(this);
    }

    fetchSubmenu = () => {
        return new Promise((resolve, reject) => {
            api.get(`/api/v1/news/lastchanged`).then((response: any) => {
                response.data.map((item, idx) => {
                    item.key = item.id;
                    item.url = `/news/edit/${item.id}`;
                    item.name = item.name && item.name.length > 0 ? item.name : localize("IngenTitel");
                });

                resolve(response.data);
            });
        });
    };

    preparePayload = () => {
        let payload = {} as any;
        if (this.refs.tiny) {
            payload = {
                ...this.state.article,
                // @ts-ignore
                content: this.refs.tiny.getContent(),
            };
        } else {
            payload = { ...this.state.article };
        }

        payload.targets = payload.targets?.map((t) => ({
            ...t,
            id: t.key || t.id,
        }));

        payload.hiddentargets = payload.hiddentargets?.map((t) => ({
            ...t,
            id: t.key || t.id,
        }));

        payload.introTitle = payload.name;

        payload.introPreamble = payload.preamble;

        if (payload.uberContent) {
            payload.content = extractHtmlFromUberContent(payload.uberContent);
        }

        return payload;
    };

    public onSubmit = (createDraft = false, isAutosave = false) => {
        const enablePrivilegesMatrix = this.props.instance.get("enablePrivilegesMatrix");
        const enableContentReview = this.props.instance.get("enableContentReview");

        let payload = this.preparePayload();
        payload.isAutosave = isAutosave;

        if (createDraft) {
            payload.isDraft = true;
        } else {
            payload.isDraft = false;
            payload.isPublished = true;
        }

        this.setState(
            {
                isAutosaving: isAutosave ? true : this.state.isAutosaving,
                isLoading: isAutosave ? this.state.isLoading : true,
            },
            () => {
                api.post(`/api/v1/news/${this.state.article.id}`, payload)
                    .then((response) => {
                        if (isAutosave) {
                            this.setState({
                                article: {
                                    ...this.state.article,
                                    id: response.data.id,
                                },
                                isAutosaving: false,
                                lastAutosave: new Date(),
                            });
                            return;
                        }

                        scrollToTop();
                        let d = response.data;
                        if (d.attachedStartDate) d.attachedStartDate = new Date(d.attachedStartDate);
                        if (d.attachedEndDate) d.attachedEndDate = new Date(d.attachedEndDate);
                        if (d.publishStartDate) d.publishStartDate = new Date(d.publishStartDate);
                        if (d.publishEndDate) d.publishEndDate = new Date(d.publishEndDate);
                        if (d.preamble) d.preamble = d.preamble.replace(/<br\s*[\/]?>/gi, "\n");

                        this.setState({
                            article: d,
                            saveSuccess: true,
                            isLoading: false,
                            saveError: [],
                        });
                        this.referenceModel = { ...this.state.article };
                        if (
                            !(this.props.isAdmin || this.props.isEditor) &&
                            enablePrivilegesMatrix &&
                            enableContentReview
                        ) {
                            this.showDialog(localize("DinaAndringarHarSkickatsInForGranskning"));
                        } else {
                            const onComplete = () => {
                                let pathname = "/news/" + d.menuUrl.split("/").slice(1).join("/");
                                this.props.history.push({ pathname });
                            };
                            if (this.state.sendAsEmail) {
                                this.props.dispatch(
                                    setConfirmPopup({
                                        title: localize("ArDuSakerAttDuVillSkickaArtikelTillAllaMottagareSomEpost"),
                                        onConfirm: () => {
                                            api.post(`/api/v1/news/${d.id}/sendasemail`)
                                                .then(onComplete)
                                                .catch(onComplete);
                                        },
                                        onDismiss: () => {
                                            onComplete();
                                        },
                                        isOpen: true,
                                    })
                                );
                            } else {
                                onComplete();
                            }
                        }
                    })
                    .catch((saveError) => {
                        scrollToTop();
                        appInsights.trackException({
                            error: new Error(saveError),
                            severityLevel: SeverityLevel.Error,
                        });
                        this.setState((prevState) => ({
                            article: {
                                ...prevState.article,
                                content: payload.content,
                            },
                            isAutosaving: false,
                            isLoading: false,
                            saveError: saveError.response.data.errorlist,
                        }));
                    });
            }
        );
    };

    private _handlePivotChange = (item: PivotItem): void => {
        if (this.refs.tiny) {
            this.setState((state) => ({
                article: {
                    ...state.article,
                    //@ts-ignore
                    content: this.refs.tiny.getContent(),
                },
            }));
        }
    };

    private fetch = async (id, versionId = null, route = null) => {
        this.setState({ isLoading: true });

        if (route) {
            try {
                const response = await api.get("/api/v1/routes", {
                    maxRedirects: 0,
                    validateStatus: (status) => status < 400,
                    params: { route },
                });

                id = response.data.id;
            } catch (err) {
                try {
                    route = route.replace("nyheter/", "news/");

                    const res2 = await api.get("/api/v1/routes", {
                        maxRedirects: 0,
                        validateStatus: (status) => status < 400,
                        params: { route },
                    });

                    id = res2.data.id;
                } catch (err2) {
                    /* ignored */
                }
            }
        }

        const params = {
            useElevatedPrivileges: true,
            ...(versionId ? { versionId } : {}),
        };

        const response = await api.get(`/api/v1/news/alt/${id}`, { params });

        let d = response.data;
        let attachedStartDate, attachedEndDate, publishStartDate, publishEndDate, preamble;
        if (d.attachedStartDate) attachedStartDate = new Date(d.attachedStartDate);
        if (d.attachedEndDate) attachedEndDate = new Date(d.attachedEndDate);
        if (d.publishStartDate) publishStartDate = new Date(d.publishStartDate);
        if (d.publishEndDate) publishEndDate = new Date(d.publishEndDate);
        preamble = d.preamble.replace(/<br\s*[\/]?>/gi, "\n");

        const attachedExternalFiles = await this.fetchExternalFiles(d.attachedExternalFiles);

        this.setState({
            article: {
                ...(response.data as Spintr.INewsArticle),
                visibleToEveryone: !response.data.targets.length,
                attachedStartDate,
                attachedEndDate,
                publishStartDate,
                publishEndDate,
                preamble,
                publisher: {
                    id: response.data.publisherId,
                    name: response.data.publisherName,
                    imageUrl: response.data.publisherImage,
                    type: SpintrTypes.UberType.User,
                    typeName: "",
                    info: response.data.publisherDepartment,
                },
                usePreviewImage: !!response.data.previewImage && response.data.previewImage.length > 0,
                attachedExternalFiles
            },
            isLoading: false,
        });
        this.referenceModel = { ...this.state.article };
    };

    fetchExternalFiles = async (attachedExternalFiles?: any[]) => {
        if (!attachedExternalFiles || attachedExternalFiles.length === 0) {
            return attachedExternalFiles;
        }

        const promises = attachedExternalFiles.map((file: any) => {
            return api
                .get(`/api/files/office365/${file.externalId}`, { params: { source: file.source } })
                .catch(() => null);
        });

        const responses: AxiosResponse[] = await Promise.all(promises);

        return responses.map((x: AxiosResponse) => {
            return {
                ...x.data,
                externalId: x.data.id
            }
        }).filter(x => !!x);
    }

    setUberCategories() {
        api.get("/api/v1/ubercategories?take=100")
            .then((response: any) => {
                this.setState({
                    uberCategories: [
                        {
                            key: 0,
                            text: localize("Ingen"),
                            id: 0,
                        },
                        ...response.data.items.map((item: any) => {
                            return {
                                ...item,
                                key: item.id,
                                text: item.name,
                            };
                        }),
                    ],
                });
            })
            .catch(() => {});
    }

    public componentDidMount = () => {
        this.setUberCategories();

        const { id, slug, versionId } = this.props.match.params;
        if (id) {
            this.fetch(id, versionId);
        } else if (slug) {
            this.fetch(0, 0, `nyheter/${slug}`);
        } else {
            this.setState({ isLoading: false });
        }
    };

    public componentDidUpdate = (prevProps) => {
        if (this.props.match.params["id"] !== prevProps.match.params["id"] && this.props.match.params["id"]) {
            this.fetch(this.props.match.params["id"]);
        }
    };

    private setReviewStatus = (status) => {
        this.setState({ isLoading: true }, () => {
            const obj = {
                id: this.state.article.contentReview.id,
                status: status,
                message: this.state.rejectMessage,
            };

            api.put(`/api/v1/contentreview/${this.state.article.contentReview.id}`, obj).then(() => {
                this.setState({ isLoading: false, showRejectModal: false });
                if (status === true) {
                    fetchMenu(this.props.dispatch, this.props.currentUser);
                    this.props.history.push(`/goto/${this.state.article.id}`);
                } else {
                    this.setState({
                        article: {
                            ...this.state.article,
                            contentReview: {
                                ...this.state.article.contentReview,
                                status: false,
                            },
                        },
                    });
                }
            });
        });
    };

    closeRejectModal = () => {
        const close = () => {
            this.setState({
                showRejectModal: false,
            });
        };

        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: close,
                })
            );
        } else {
            close();
        }
    };

    private renderRejectModal = () => {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showRejectModal}
                onDismiss={this.closeRejectModal.bind(this)}
            >
                <PopupHeader
                    text={localize("Avsla") + ' "' + this.state.article.name + '"'}
                    onClose={this.closeRejectModal.bind(this)}
                />
                <div className="page-form-view-reject-modal">
                    <TextField
                        value={this.state.rejectMessage}
                        onChange={(_ev, rejectMessage) => {
                            this.setState({ rejectMessage });
                        }}
                        multiline={true}
                        label={localize("Kommentar_big")}
                    />
                    <DefaultButton onClick={this.closeRejectModal.bind(this)}>{localize("Avbryt")}</DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.setReviewStatus(false);
                        }}
                    >
                        {localize("Avsla")}
                    </PrimaryButton>
                </div>
            </Modal>
        );
    };

    private renderContentReview = () => {
        const { article } = this.state;

        return (
            <div className="contentReviewBar">
                {(this.props.isAdmin || this.props.isEditor) &&
                article.contentReview &&
                article.contentReview.status !== true &&
                article.contentReview.status !== false ? (
                    <div className="container">
                        <div className="adminBar">
                            <div className="left">{localize("GranskningAvNyhet")}</div>
                            <div className="right">
                                <PrimaryButton
                                    onClick={() => {
                                        this.setReviewStatus(true);
                                    }}
                                >
                                    {localize("Godkann")}
                                </PrimaryButton>
                                <DefaultButton
                                    onClick={() => {
                                        this.setState({
                                            showRejectModal: true,
                                        });
                                    }}
                                >
                                    {localize("Avsla")}
                                </DefaultButton>
                            </div>
                        </div>
                    </div>
                ) : (
                    article.contentReview.status === false &&
                    article.contentReview.message &&
                    article.contentReview.message.length > 0 && (
                        <div className="container">
                            <div className="userBar">
                                <div className="messageHeader">
                                    {localize("KommentarFran")} {article.contentReview.reviewedByName}:
                                </div>
                                <div className="message">{article.contentReview.message}</div>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    };

    showDialog = (message): void => {
        this.setState({
            hideDialog: false,
            dialogMessage: message,
        });
    };

    closeDialog = (): void => {
        this.setState({ hideDialog: true }, () => {
            // const { id } = this.state.page;
            // if (id && id !== 0) {
            //     this.props.history.push(`/pages/${id}/edit`);
            // }
        });
    };

    private handleSaveDraftClick = () => {
        let { id, slug } = this.props.match.params;
        if (!id && !slug) {
            id = 0;
        } else if (slug) {
            id = this.state.article.id;
        }

        if (id !== 0) {
            let payload = this.preparePayload();

            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    api.post(`/api/v1/news/${id}/createdraft/`, payload).then(() => {
                        this.setState({
                            isLoading: false,
                            showInfoDialog: true,
                            infoDialogMessage: localize("NyhetSparadSomUtkast"),
                        });
                    });
                }
            );
        } else {
            this.onSubmit(true);
        }
    };

    private hasModelChanged(): boolean {
        return (
            this.referenceModel &&
            (this.referenceModel.name !== this.state.article.name ||
                this.referenceModel.preamble !== this.state.article.preamble ||
                this.referenceModel.content !== this.state.article.content)
        );
    }

    private autosaveNeeded(): boolean {
        // TODO: not quite sure what this was used for...
        /*
        if (versionLoaded) {
            versionLoaded = false;
            return false;
        }
        */

        if (this.state.isAutosaving) {
            return false;
        }

        return this.hasModelChanged();
    }

    private async onAutosave() {
        const isAdmin =
            this.props.currentUser.roles.indexOf("administrators") || this.props.currentUser.roles.indexOf("editor");

        if (!isAdmin || !this.autosaveNeeded()) {
            return;
        }

        this.referenceModel = { ...this.state.article };

        if (!this.referenceModel.name &&
            !this.referenceModel.content) {
            return;
        }

        if (this.state.article.isNew) {
            this.onSubmit(false, true);
            return;
        }

        this.setState({ isAutosaving: true }, async () => {
            try {
                const version = await autosave({
                    headline: this.referenceModel.name,
                    id: this.state.autosaveId,
                    preamble: this.referenceModel.preamble,
                    text: this.referenceModel.content,
                    uberId: this.referenceModel.id,
                });

                this.setState({
                    autosaveId: version.id,
                    isAutosaving: false,
                    lastAutosave: new Date(),
                });
            } catch (_) {
                this.setState({ isAutosaving: false });
            }
        });
    }

    private onContentChanged(content: string) {
        console.log("onContentChange");
        this.setState({
            ...this.state,
            article: {
                ...this.state.article,
                content,
            },
        });
    }

    private toggleDisplayPreview = () => {
        this.setState(
            (prevState) => ({
                displayPreview: !prevState.displayPreview,
            }),
            () => {
                scrollToTop();
            }
        );
    };

    private renderFormFooterBar = () => (
        <FormFooterBar
            top={!this.props.isSmallViewMode}
            // autosaveText={
            //     !this.state.lastAutosave
            //         ? null
            //         : localize("Autosparat") + " " + moment(this.state.lastAutosave).format("HH:mm")
            // }
            onRenderLeft={
                this.props.isSmallViewMode// || this.state.lastAutosave
                    ? undefined
                    : () => (
                          <Breadcrumbs
                              displayInstance
                              items={[
                                  ...(this.adminNewsEditView
                                      ? [
                                            {
                                                text: localize("Administration"),
                                                key: 2,
                                                link: "/admin",
                                            },
                                            {
                                                text: localize("Nyheter"),
                                                key: 3,
                                                link: "/admin/news",
                                            },
                                        ]
                                      : []),
                                  {
                                      text: this.isNew ? localize("SkapaEnNyhet") : localize("RedigeraNyhetsartikeln"),
                                      key: 4,
                                      link: window.location.pathname,
                                  },
                              ]}
                          />
                      )
            }
            onCancelClick={() => {
                this.props.history.goBack();
            }}
            saveText={localize("Publicera")}
            onSaveClick={() => {
                this.setState({
                    enableFieldValidation: true,
                });
                this.onSubmit(false);
            }}
            onSaveDraftClick={this.handleSaveDraftClick}
            confirmSave={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("PubliceraDetta").toLowerCase())}
            onPreviewClick={this.toggleDisplayPreview}
        />
    );

    render() {
        const {
            article,
            saveSuccess,
            saveError,
            showInfoDialog,
            infoDialogMessage,
            isLoading,
            selectedTemplateAfterAttached,
            differentDisplayFormatAfterAttached,
        } = this.state;
        const uberType = SpintrTypes.UberType;

        if (isLoading) {
            return <Loader />;
        }

        if (this.state.displayPreview) {
            return (
                <>
                    <NewsArticleView article={article} isPreview goBackToFormCallback={this.toggleDisplayPreview} />
                    {this.renderFormFooterBar()}
                </>
            );
        }

        const allFiles = [...(article.attachedFiles || []), ...(article.attachedExternalFiles || [])];

        return (
            <div className={"NewsEditView " + (this.adminNewsEditView ? "apply-padding" : "")}>
                {!this.state.isLoading && <Autosaver model={this.state.article} onAutosave={this.onAutosave} />}
                <div>
                    {this.contentReview && this.renderContentReview()}

                    {this.renderRejectModal()}

                    {showInfoDialog && (
                        <CustomDialog
                            message={infoDialogMessage}
                            show={showInfoDialog}
                            onDismiss={() => {
                                this.setState({ showInfoDialog: false });
                            }}
                            onConfirm={() => {
                                this.setState({ showInfoDialog: false });
                            }}
                        />
                    )}
                    {saveSuccess && (
                        <SuccessMessagebar
                            message={localize("SparningenLyckades")}
                            onDismiss={() => {
                                this.setState({ saveSuccess: false });
                            }}
                        />
                    )}
                    {saveError && saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({ saveError: [] });
                            }}
                            aboveSplitForm
                        />
                    )}

                    <Dialog
                        hidden={this.state.hideDialog}
                        containerClassName="dialogWithPopupHeader"
                        onDismiss={this.closeDialog}
                        modalProps={{
                            isBlocking: true,
                            allowTouchBodyScroll: true,
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: localize("Meddelande"),
                            closeButtonAriaLabel: localize("Stang"),
                            subText: this.state.dialogMessage,
                        }}
                    >
                        <PopupHeader
                            text={localize("Meddelande")}
                            subText={this.state.dialogMessage}
                            onClose={this.closeDialog}
                        />
                        <DialogFooter>
                            <PrimaryButton theme={this.context} onClick={this.closeDialog} text={localize("Ok")} />
                        </DialogFooter>
                    </Dialog>

                    {this.state.showImageSelector && (
                        <ContentImageViewerAndSelector
                            displayFileSelector
                            cropImage
                            imageUrl={article.image}
                            editMode={false}
                            height={340}
                            enableMediaflow={this.props.enableMediaflow}
                            enableUnsplash={this.props.enableUnsplash}
                            renderSizeString={() => {
                                return `${localize("OptimalBildStorlek")}: 1000x340px`;
                            }}
                            onChange={(result) => {
                                this.setState((state) => ({
                                    article: {
                                        ...state.article,
                                        image: result.thumbnailUrl,
                                        imageTicket: {
                                            ticket: result.ticket,
                                            data: result.base64,
                                            remove: false,
                                            mediaflowId: result.externalMediaflowId,
                                            hotlink: result.isUnsplash,
                                            image: result.thumbnailUrl,
                                        },
                                    },
                                    showImageSelector: false,
                                }));
                            }}
                        />
                    )}

                    <SplitForm
                        headerTitle={localize(this.state.article.isNew ? "SkapaEnNyhet" : "RedigeraNyhetsartikeln")}
                        displayMandatoryText
                        additionalSettings={[
                            {
                                title: localize("Malgrupp"),
                                content: (
                                    <>
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                showAllWhenEmpty
                                                items={
                                                    !article.targets
                                                        ? []
                                                        : article.targets.map((t) => {
                                                              return {
                                                                  ...t,
                                                                  key: t.key ? t.key : t.id,
                                                                  id: t.id ? t.id : t.key,
                                                              };
                                                          })
                                                }
                                                label={localize("SynligFor")}
                                                types={[
                                                    uberType.Department,
                                                    uberType.Office,
                                                    uberType.Role,
                                                    uberType.TargetGroup,
                                                    ...(this.props.instance.get("enableCompanyLevel")
                                                        ? [uberType.Company]
                                                        : []),
                                                ]}
                                                onChange={(targets) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            targets: targets.map((t) => {
                                                                return {
                                                                    ...t,
                                                                    id: t.key,
                                                                };
                                                            }),
                                                        },
                                                    }));
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("GorNyhetenSynligForAllaINyhetsarkivet")}
                                                checked={!article.restrictToTargets}
                                                onChange={(
                                                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                    checked?: boolean
                                                ) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            restrictToTargets: !checked,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                items={article.hiddentargets}
                                                label={localize("DoldFor")}
                                                types={[
                                                    uberType.Department,
                                                    uberType.Office,
                                                    uberType.Role,
                                                    uberType.TargetGroup,
                                                    ...(this.props.instance.get("enableCompanyLevel")
                                                        ? [uberType.Company]
                                                        : []),
                                                ]}
                                                onChange={(hiddentargets) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            hiddentargets,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </FormControl>
                                    </>
                                ),
                            },
                            {
                                title: localize("Metadata"),
                                content: (
                                    <>
                                        {this.state.uberCategories && this.state.uberCategories.length > 1 && (
                                            <FormControl>
                                                <Dropdown
                                                    label={localize("Kategori")}
                                                    selectedKey={
                                                        !!article.uberCategories && article.uberCategories.length > 0
                                                            ? article.uberCategories[0].id
                                                            : 0
                                                    }
                                                    onChange={(_e, v) => {
                                                        this.setState({
                                                            article: {
                                                                ...this.state.article,
                                                                uberCategories: [
                                                                    this.state.uberCategories.find(
                                                                        (uc) => uc.id === Number(v.key)
                                                                    ),
                                                                ],
                                                            },
                                                        });
                                                    }}
                                                    options={this.state.uberCategories}
                                                    styles={{
                                                        dropdown: {
                                                            width: 250,
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                items={article.publisher ? [article.publisher] : []}
                                                label={localize("Ansvarig_Utgivare")}
                                                types={[SpintrTypes.UberType.User]}
                                                itemLimit={1}
                                                onChange={(p) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            publisherId: p[0]?.key,
                                                            publisher:
                                                                p.length > 0
                                                                    ? ({
                                                                          id: p[0].key,
                                                                          type: SpintrTypes.UberType.User,
                                                                          name: p[0].name,
                                                                          typeName: "Person",
                                                                          info: p[0].subText,
                                                                          imageUrl: p[0].imageUrl,
                                                                      } as Spintr.IUser)
                                                                    : null,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                hideImage={true}
                                                enableNewItems={true}
                                                items={article?.tags?.map((tag) => {
                                                    return {
                                                        name: tag,
                                                    };
                                                })}
                                                label={localize("SokBlandTaggar")}
                                                onChange={(tags) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            tags: tags.map((t) => {
                                                                return t.name;
                                                            }),
                                                        },
                                                    }));
                                                }}
                                                showTags
                                                // noAutocomplete
                                            />
                                        </FormControl>
                                    </>
                                ),
                            },
                            {
                                title: localize("Avancerat"),
                                content: (
                                    <>
                                        <FormControl>
                                            <div className="horizontal-dates">
                                                <SpintrDatePicker
                                                    label={localize("Publiceringsdatum")}
                                                    value={article.publishStartDate}
                                                    onChangeHandler={(date) =>
                                                        this.setState((prevState) => ({
                                                            article: {
                                                                ...prevState.article,
                                                                publishStartDate: date,
                                                            },
                                                        }))
                                                    }
                                                />
                                                <SpintrDatePicker
                                                    isClearable={true}
                                                    label={localize("Slutdatum")}
                                                    value={article.publishEndDate}
                                                    minDate={article.publishStartDate}
                                                    onChangeHandler={(date) =>
                                                        this.setState((prevState) => ({
                                                            article: {
                                                                ...prevState.article,
                                                                publishEndDate: date,
                                                            },
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </FormControl>
                                        <FormControl>
                                            <Toggle
                                                label={localize("TillatKommentarer")}
                                                checked={article.allowComments}
                                                onChange={() => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            allowComments: !state.article.allowComments,
                                                        },
                                                    }));
                                                }}
                                                onText={localize("Ja")}
                                                offText={localize("Nej")}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Dropdown
                                                label={localize("ValjSprak")}
                                                selectedKey={article.language ? article.language : ""}
                                                onChange={(
                                                    _event: React.FormEvent<HTMLDivElement>,
                                                    item: IDropdownOption
                                                ) => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            language: item.key.toString(),
                                                        },
                                                    }));
                                                }}
                                                options={getLanguageOptions()}
                                                styles={{
                                                    dropdown: {
                                                        width: 300,
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Toggle
                                                label={localize("SkickaSomEpost")}
                                                checked={this.state.sendAsEmail}
                                                onChange={() => {
                                                    this.setState((prevState) => ({
                                                        sendAsEmail: !prevState.sendAsEmail,
                                                    }));
                                                }}
                                                onText={localize("Ja")}
                                                offText={localize("Nej")}
                                            />
                                        </FormControl>
                                        <FormControl label={localize("Forhandsgranskning")}>
                                            <InformationFeedEntry
                                                entry={{
                                                    ...article,
                                                    url: "/",
                                                    imageUrl: article.previewImage
                                                        ? article.previewImage
                                                        : article.image,
                                                    title: article.name,
                                                }}
                                                isPreview={true}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Toggle
                                                data-cy="clickFast"
                                                label={localize("FastNyhet")}
                                                checked={article.isAttached}
                                                onChange={() => {
                                                    this.setState((state) => ({
                                                        article: {
                                                            ...state.article,
                                                            isAttached: !state.article.isAttached,
                                                            attachedStartDate: !state.article.isAttached
                                                                ? new Date()
                                                                : undefined,
                                                            attachedEndDate: !state.article.isAttached
                                                                ? moment(new Date()).add(1, "week").toDate()
                                                                : undefined,
                                                        },
                                                    }));
                                                }}
                                                onText={localize("Ja")}
                                                offText={localize("Nej")}
                                            />
                                        </FormControl>
                                        {article.isAttached && (
                                            <>
                                                <FormControl>
                                                    <div className="horizontal-dates">
                                                        <SpintrDatePicker
                                                            label={localize("ATTACHED_FROM")}
                                                            value={article.attachedStartDate}
                                                            onChangeHandler={(date) =>
                                                                this.setState((prevState) => ({
                                                                    article: {
                                                                        ...prevState.article,
                                                                        attachedStartDate: date,
                                                                    },
                                                                }))
                                                            }
                                                        />
                                                        <SpintrDatePicker
                                                            isClearable={true}
                                                            label={localize("Till")}
                                                            value={article.attachedEndDate}
                                                            minDate={article.attachedStartDate}
                                                            onChangeHandler={(date) =>
                                                                this.setState((prevState) => ({
                                                                    article: {
                                                                        ...prevState.article,
                                                                        attachedEndDate: date,
                                                                    },
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </FormControl>
                                                <FormControl>
                                                    <Dropdown
                                                        label={localize("ATTACHED_BACKGROUND_COLOR")}
                                                        selectedKey={
                                                            article.attachedBackground
                                                                ? article.attachedBackground
                                                                : undefined
                                                        }
                                                        onChange={(_e, v) => {
                                                            this.setState((state) => ({
                                                                article: {
                                                                    ...state.article,
                                                                    attachedBackground: Number(v.key),
                                                                },
                                                            }));
                                                        }}
                                                        options={[
                                                            {
                                                                key: 1,
                                                                text: localize("Ingen"),
                                                            },
                                                            {
                                                                key: 2,
                                                                text: localize("Gradient"),
                                                            },
                                                            {
                                                                key: 3,
                                                                text: localize("IntranatetsFarg"),
                                                            },
                                                        ]}
                                                        styles={{
                                                            dropdown: {
                                                                width: 300,
                                                            },
                                                        }}
                                                    />
                                                </FormControl>
                                            </>
                                        )}
                                    </>
                                ),
                            },
                            {
                                title:
                                    localize("Versioner") +
                                    " " +
                                    localize("Och_small") +
                                    " " +
                                    localize("Utkast_Plural").toLowerCase(),
                                enabled: article.id > 0,
                                content: (
                                    <ContentVersionsList
                                        uberId={this.state.article.id}
                                        isNews
                                        onContentVersionSelected={(data: any) => {
                                            this.setState(
                                                (state) => ({
                                                    article: {
                                                        ...state.article,
                                                        name: data.Headline,
                                                        preamble: data.Introduction,
                                                        content: data.Text,
                                                    },
                                                    isLoading: true,
                                                }),
                                                () => {
                                                    this.setState({
                                                        isLoading: false,
                                                    });

                                                    this.showDialog(localize("VERISON_APPLIED"));
                                                }
                                            );
                                        }}
                                    />
                                ),
                            },
                        ]}
                        lowerAdditionalSettings={[
                            {
                                title: localize("Prioritet"),
                                content: (
                                    <FormControl>
                                        <Label className="control-description" size="body-2">{localize("IMPORTANCE_DESCRIPTION")}</Label>
                                        <Dropdown
                                            selectedKey={article.prioLevel}
                                            onChange={(_e, v) => {
                                                this.setState({
                                                    article: {
                                                        ...this.state.article,
                                                        prioLevel: Number(v.key),
                                                    },
                                                });
                                            }}
                                            options={[
                                                {
                                                    key: 0,
                                                    text: localize("Standard"),
                                                },
                                                {
                                                    key: 4,
                                                    text: localize("NICE_TO_KNOW"),
                                                },
                                                {
                                                    key: 5,
                                                    text: localize("NEED_TO_KNOW"),
                                                },
                                            ]}
                                            styles={{
                                                dropdown: { width: 250 },
                                            }}
                                        />
                                    </FormControl>
                                ),
                            },
                        ]}
                    >
                        <FormSection>
                            <FormControl>
                                <ContentImageViewerAndSelector
                                    renderSizeString={() => {
                                        return `${localize("OptimalBildStorlek")}: 1000x340px <br />`;
                                    }}
                                    cropImage
                                    cropAspect={SpintrTypes.ImageCropAspect.Wide}
                                    imageUrl={article.image}
                                    editMode={true}
                                    height={340}
                                    enableMediaflow={this.props.enableMediaflow}
                                    enableUnsplash={this.props.enableUnsplash}
                                    onChange={(result) => {
                                        this.setState((state) => ({
                                            article: {
                                                ...state.article,
                                                image: result.thumbnailUrl,
                                                imageTicket: {
                                                    ticket: result.ticket,
                                                    remove: result.thumbnailUrl ? false : true,
                                                    data: result.base64,
                                                    mediaflowId: result.externalMediaflowId,
                                                    hotlink: result.isUnsplash,
                                                    image: result.thumbnailUrl,
                                                },
                                            },
                                            showImageSelector: false,
                                        }));
                                    }}
                                    blur
                                />
                            </FormControl>
                            <FormControl>
                                <FieldCompletion
                                    anchorPosition="TopRight"
                                    completionType={SpintrTypes.CompletionType.HeadingSuggestion}
                                    onTextGenerated={(text) =>
                                        this.setState((state) => ({
                                            ...state,
                                            article: {
                                                ...state.article,
                                                name: text,
                                            },
                                        }))
                                    }
                                    offsetX={10}
                                    offsetY={32}
                                    referenceText={article.content}
                                >
                                    <TextField
                                        data-cy="news-headline"
                                        ariaLabel={localize("SkrivEnRubrik")}
                                        label={localize("Rubrik")}
                                        value={article.name}
                                        onChange={(
                                            _?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                            name?: string
                                        ) =>
                                            this.setState((state) => ({
                                                article: {
                                                    ...state.article,
                                                    name,
                                                },
                                            }))
                                        }
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                event.stopPropagation(); // Prevents page from reloading
                                                event.preventDefault();
                                            }
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FieldCompletion>
                            </FormControl>
                            {this.state.displayPreamble || article.preamble?.length > 0 ? (
                                <DivWithHoverActions
                                    actions={[
                                        {
                                            icon: "trash",
                                            title: localize("TaBort"),
                                            onClick: () => {
                                                this.setState({
                                                    article: {
                                                        ...article,
                                                        preamble: "",
                                                    },
                                                    displayPreamble: false,
                                                });
                                            },
                                        },
                                    ]}
                                    displayActionsAtBottom={true}
                                >
                                    <FormControl>
                                        <FieldCompletion
                                            anchorPosition="TopRight"
                                            completionType={SpintrTypes.CompletionType.IntroductionSuggestion}
                                            onTextGenerated={(text) =>
                                                this.setState((state) => ({
                                                    ...state,
                                                    article: {
                                                        ...state.article,
                                                        preamble: text,
                                                    },
                                                }))
                                            }
                                            offsetX={10}
                                            offsetY={32}
                                            staggeredOutput={true}
                                            referenceText={article.content}
                                        >
                                            <TextField
                                                className="preamble-input-field"
                                                data-cy="news-ingress"
                                                label={localize("Ingress")}
                                                value={article.preamble}
                                                onChange={(event: React.FormEvent, preamble: string) => {
                                                    this.setState((state) => ({
                                                        article: { ...state.article, preamble },
                                                    }));
                                                }}
                                                multiline={true}
                                                rows={5}
                                            />
                                        </FieldCompletion>
                                    </FormControl>
                                </DivWithHoverActions>
                            ) : (
                                <CenteredAddButton
                                    text={localize("LaggTillEnIngress")}
                                    onClick={() => {
                                        this.setState({
                                            displayPreamble: true,
                                        });
                                    }}
                                />
                            )}
                            {!this.props.enableBuilder ? (
                                <FormControl>
                                    <TinyEditorComponent
                                        required
                                        aria-required={true}
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        label={localize("BrodText")}
                                        ref={"tiny"}
                                        id={"23"}
                                        enableMediaflow={this.props.enableMediaflow}
                                        enableUnsplash={this.props.enableUnsplash}
                                        content={article.content}
                                        onChange={this.onContentChanged}
                                        />
                                </FormControl>
                            ) : (
                                <EditableContentSections
                                    contentRows={this.state.article.uberContent?.uberContentRows || []}
                                    onChange={(newUberContentRows) => {
                                        this.setState({
                                            article: {
                                                ...this.state.article,
                                                uberContent: {
                                                    ...(this.state.article.uberContent || {}),
                                                    uberContentRows: newUberContentRows,
                                                },
                                            },
                                            
                                        });
                                    }}
                                    createBlockIfEmpty={this.state.article && this.state.article.id === 0}
                                />
                            )}
                        </FormSection>
                        {!!allFiles && allFiles.length > 0 && (
                            <div className="SimpleFiles">
                                {allFiles.map((item: any, index: number) => {
                                    return (
                                        <SimpleFile
                                            file={item}
                                            key={index}
                                            onRemove={() => {
                                                this.setState({
                                                    article: {
                                                        ...this.state.article,
                                                        attachedFiles: (this.state.article.attachedFiles || []).filter(
                                                            (x) => x.id !== item.id
                                                        ),
                                                        attachedExternalFiles: (
                                                            this.state.article.attachedExternalFiles || []
                                                        ).filter((x) => x.id !== item.id),
                                                        attachedFilesAndFolders: (
                                                            this.state.article.attachedFilesAndFolders || []
                                                        ).filter((x) => x.id !== item.id),
                                                    },
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <CenteredAddButton
                            text={localize("LaggTillFiler")}
                            onClick={() => {
                                this.setState({
                                    isFilePickerOpen: true,
                                });
                            }}
                        />
                    </SplitForm>

                    {this.renderFormFooterBar()}
                </div>
                {this.state.isFilePickerOpen && (
                    <FilePicker
                        onClose={() => {
                            this.setState({
                                isFilePickerOpen: false,
                            });
                        }}
                        onSelect={(data, source) => {
                            let newAttachedFiles = [
                                ...(this.state.article.attachedFiles ? this.state.article.attachedFiles : []),
                            ];

                            let newAttachedExternalFiles = [
                                ...(this.state.article.attachedExternalFiles
                                    ? this.state.article.attachedExternalFiles
                                    : []),
                            ];

                            for (let i of data) {
                                if (
                                    source === SpintrTypes.FolderRootSource.Office365Public ||
                                    source === SpintrTypes.FolderRootSource.Office365Private
                                ) {
                                    newAttachedExternalFiles.push({
                                        ...i,
                                        source: source,
                                        externalId: i.id
                                    });
                                } else {
                                    newAttachedFiles.push(i);
                                }
                            }

                            this.setState({
                                article: {
                                    ...this.state.article,
                                    attachedFiles: newAttachedFiles,
                                    attachedExternalFiles: newAttachedExternalFiles,
                                },
                            });
                        }}
                        showFiles
                        allowMultipleFiles={true}
                        sourcesToDisplay={[
                            SpintrTypes.FolderRootSource.Office365Public,
                            SpintrTypes.FolderRootSource.SpintrPublic,
                        ]}
                        source={SpintrTypes.FolderRootSource.SpintrPublic}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instanceName: state.instance.name,
    currentUser: state.profile.active,
    instance: state.instance,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    currentUserId: state.profile.active.id,
    enableMediaflow: state.instance.get("enableMediaflow"),
    enableUnsplash: state.instance.get("enableUnsplash"),
    enableBuilder: state.instance.get("enableBuilderForNews"),
    disableCommentsByDefault: state.instance.get("disableCommentsByDefault"),
    targetToCompany: state.instance.get("forceCompanyFeed") as boolean,
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(NewsEditView);
