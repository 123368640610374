import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Icon, Link, Modal } from "@fluentui/react";
import React, {
    Component, ReactNode
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { addRemoteConversation, queryConversations, setTabState } from "src/chat";
import { createConversation } from "src/chat/api";
import { Conversation } from "src/chat/conversation-types";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Label, Loader, setDisplayAsOfflineInChat, SpintrUser, UnstyledButton } from "src/ui";
import ContentWithUnreadIndicator from "src/ui/components/UnreadIndicator/ContentWithUnreadIndicator";
import { ConversationItem } from "../ConversationItem";
import { ConversationsPanelHeader } from "../ConversationsPanelHeader";
import "./ConversationsPanel.scss";
import GroupConversationItem from "src/groups/views/GroupConversationItem/GroupConversationItem";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import classnames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import GroupInvitationNotification from "src/notifications/components/GroupInvitationNotification";
import { circleLarge } from "src/ui/helpers/style";

interface IStateProps {
    conversations: Conversation[];
    isLoading?: boolean;
    hasMore?: boolean;
    hasLoadedConversations?: boolean;
    dispatch?: any;
    currentUser?: any;
    displayAsOfflineInChat: boolean;
    unreadEmails?: number;
    googleConnected: boolean;
    office365Connected: boolean;
    exchangeWebmailUrl?: string;
    emailEnabled: boolean;
    allowAdministratorsAccessToAllGroups: boolean;
    disableGroupCreation: boolean;
}

interface OwnProps {
    fullscreen?: boolean;
    calculateScrollHeight?: boolean;
    openChatTabOnClick?: boolean;
    fetchType?: SpintrTypes.ConversationsFetchType;
    onConversationClick?: any;
}

type Props = IStateProps & OwnProps & RouteComponentProps;

interface IState {
    isLoadingSearch: boolean;
    isSearching: boolean;
    searchItems: Conversation[];
    fetchType: SpintrTypes.ConversationsFetchType;
    filter: string;
    infiniteScrollHeight?: number;
    iconsKey: number;
    allGroups: any;
    displayMembersForGroup?: any;
}

class ConversationsPanel extends Component<Props, IState> {
    //private searchSubscription: Subscription;
    private heightTimeout : number;
    private conversationsCancelTokenSource : CancelTokenSource;
    private groupsCancelTokenSource : CancelTokenSource;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoadingSearch: false,
            isSearching: false,
            searchItems: [],
            fetchType: !!this.props.fetchType ? this.props.fetchType : SpintrTypes.ConversationsFetchType.Users,
            filter: "",
            infiniteScrollHeight: props.fullscreen || props.calculateScrollHeight ? undefined : 348,
            iconsKey: 1,
            allGroups: {
                items: [],
                hasBeenFetched: false,
                hasMore: true
            }
        };

        this.onConversationClick = this.onConversationClick.bind(this);
        // this.onSearchChange = this.onSearchChange.bind(this);
        // this.onSearchError  = this.onSearchError.bind(this);
        // this.onSearchSuccess  = this.onSearchSuccess.bind(this);
    }

    public componentDidMount(): void {
        //@ts-ignore
        if (this.props.calculateScrollHeight &&
            this.refs &&
            this.refs.InfiniteScroll &&
            //@ts-ignore
            !!this.refs.InfiniteScroll._infScroll &&
            //@ts-ignore
            !!this.refs.InfiniteScroll._infScroll.getBoundingClientRect) {
            // @ts-ignore
            this.heightTimeout = setTimeout(() => {
                //@ts-ignore
                const z = this.refs.InfiniteScroll._infScroll.getBoundingClientRect();

                const spintrHeight = window.innerHeight
                    || document.documentElement.clientHeight
                    || document.body.clientHeight;

                const infiniteScrollHeight = spintrHeight - z.top;

                this.setState({
                    infiniteScrollHeight
                });
            }, 20);
        }

        this.fetch();
    }

    public componentWillUnmount(): void {
        if (this.heightTimeout) {
            clearTimeout(this.heightTimeout);
        }
        
        if (this.conversationsCancelTokenSource) {
            this.conversationsCancelTokenSource.cancel();
        }

        if (this.groupsCancelTokenSource) {
            this.groupsCancelTokenSource.cancel();
        }
    }

    loadMore() {
        if (this.props.isLoading) {
            return;
        }

        this.fetch(true);
    }

    fetchAllGroups(isLoadMore?: boolean) {
        if (this.groupsCancelTokenSource) {
            this.groupsCancelTokenSource.cancel();
        }

        this.groupsCancelTokenSource = Axios.CancelToken.source();

        api.get(`/api/v1/groups`, {
            params: {
                isAscending: true,
                orderByColumn: "name",
                phrase: this.state.filter,
                take: 10,
                skip: isLoadMore ? this.state.allGroups.items.length : 0,
                includeSecret: this.props.allowAdministratorsAccessToAllGroups
                //notMemberOnly: true
            },
            cancelToken: this.groupsCancelTokenSource.token,
        }).then((response: AxiosResponse) => {
            this.setState({
                allGroups: {
                    items: isLoadMore ?
                        [
                            ...this.state.allGroups.items,
                            ...response.data
                        ] :
                        response.data,
                    hasMore: response.data.length > 0,
                    hasBeenFetched: true
                }
            })
        }).catch(() => { });
    }

    fetch(isLoadMore?: boolean) {
        // if (this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups &&
        //     this.props.hasLoadedConversations &&
        //     !this.props.hasMore &&
        //     this.state.allGroups.hasMore &&
        //     !this.state.filter) {
        //     return this.fetchAllGroups(isLoadMore);
        // }

        if (this.conversationsCancelTokenSource) {
            this.conversationsCancelTokenSource.cancel();
        }

        this.conversationsCancelTokenSource = Axios.CancelToken.source();

        this.props.dispatch(queryConversations({
            fetchType: this.state.fetchType,
            skip: isLoadMore ? this.props.conversations.length : 0,
            take: 20,
            filter: this.state.filter
        }, this.conversationsCancelTokenSource.token)).then(() => {
            if (this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups && !this.props.hasMore && !this.props.currentUser.isGroupUser) {
                this.fetchAllGroups(isLoadMore);
            }
        });
    }

    public render(): ReactNode {
        const localStorageId = "spintr_disableChatSound";
        const disableChatSound = localStorage.getItem(localStorageId) === "true";

        const emptyPlaceholder = this.state.fetchType === SpintrTypes.ConversationsFetchType.Groups ?
            "GROUPS_EMPTY_PLACEHOLDER" :
            "CONVERSATIONS_EMPTY_PLACEHOLDER";

        const headline = this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups ?
            "Grupper" :
            "Meddelanden";

        let hasMore = !!(typeof this.props.hasMore == typeof undefined) ? true : this.props.hasMore;

        if (this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups && !hasMore) {
            hasMore = this.state.allGroups.hasMore;
        }

        const showGroupCreateButton = this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups && !this.props.currentUser.isGroupUser;
        const headerHasContent = this.props.fullscreen || showGroupCreateButton;


        return (
            <div className={"ConversationsPanel" + (!headerHasContent ? " empty-header" : "")}>
                {
                    <div className={classnames("SpintrStaticLinksCallout-header", { "empty" : !headerHasContent})}>
                        {
                            this.props.fullscreen && (
                                <Label size="h3" as="span">{localize(headline)}</Label>
                            )
                        }
                        {
                            this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups && !this.props.currentUser.isGroupUser && !this.props.disableGroupCreation && (
                                <div className="SpintrStaticLinksCallout-header-icons" key={this.state.iconsKey}>
                                    <UnstyledButton className="SpintrStaticLinksCallout-header-icon primaryBGColor visage-box-shadow" onClick={() => {
                                        this.props.history.push({
                                            pathname: "/groups/create"
                                        });
                                    }}>
                                        <Visage2Icon icon="add" color="white" />
                                    </UnstyledButton>
                                </div>
                            )
                        }
                        {
                            this.props.fetchType !== SpintrTypes.ConversationsFetchType.Groups && (
                                <div className="SpintrStaticLinksCallout-header-icons" key={this.state.iconsKey}></div>
                            )
                        }
                    </div>
                }
                <div className="ConversationsPanel-header">
                    <ConversationsPanelHeader
                        onSearch={(filter: string) => {
                            this.setState({
                                filter,
                                allGroups: {
                                    hasBeenFetched: false,
                                    items: [],
                                    hasMore: true
                                }
                            }, () => {
                                this.fetch();
                            });
                        }}
                    />
                </div>
                <div className="list-wrapper">
                    {
                        this.props.fetchType !== SpintrTypes.ConversationsFetchType.Groups &&
                        this.props.displayAsOfflineInChat && (
                            <div className="ConversationsPanel-offline-text">
                                <Label size="body-2" color="mid-grey">
                                    {
                                        localize("CHAT_MENU_OFFLINE")
                                    }
                                    {
                                        " ("
                                    }
                                    <Link onClick={() => {
                                        api.put("/api/chat/setoffline", {
                                            value: !this.props.displayAsOfflineInChat
                                        });

                                        this.props.dispatch(setDisplayAsOfflineInChat(!this.props.displayAsOfflineInChat));
                                    }}>
                                        {
                                            localize("CHAT_MENU_DISABLE").toLowerCase()
                                        }
                                    </Link>
                                    {
                                        ")"
                                    }
                                </Label>
                            </div>
                        )
                    }
                    <InfiniteScroll
                        ref={"InfiniteScroll"}
                        key={this.state.infiniteScrollHeight}
                        dataLength={this.props.conversations.length + this.state.allGroups.items.length}
                        next={this.loadMore.bind(this)}
                        hasMore={hasMore}
                        loader={<Loader />}
                        height={this.state.infiniteScrollHeight}>
                        {
                            (!this.props.conversations || this.props.conversations.length === 0) && this.props.isLoading && (
                                <Loader />
                            )
                        }
                        {
                            (!this.props.conversations || this.props.conversations.length === 0) && this.state.allGroups.items.length === 0 && !this.props.isLoading && !this.props.hasMore && (
                                <div className="empty-sidebar-list">
                                    <div className="icon">
                                        <Visage2Icon icon="coffee" />
                                    </div>
                                    <div className="content">
                                        <Label role="text" size="body-2">
                                            <span>
                                                {localize(emptyPlaceholder)}
                                            </span>
                                        </Label>
                                    </div>
                                </div>
                            )
                        }
                        {
                            !!this.props.conversations &&
                            this.props.conversations.length > 0 &&
                            this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups && (
                                <>
                                    {this.props.conversations.some(conversation => conversation.group.membershipStatus === SpintrTypes.GroupMembershipStatus.Invited) && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="body-3"
                                                color="mid-grey"
                                                weight="medium"
                                            >
                                                {localize("INVITES")}
                                            </Label>
                                            {
                                                this.props.conversations.map((conversation) => (
                                                    conversation.group.membershipStatus === SpintrTypes.GroupMembershipStatus.Invited && (
                                                        <div
                                                            className="ConversationItem invite"
                                                            key={conversation.id}
                                                        >
                                                            <div className="image">
                                                                <SpintrUser
                                                                    name={conversation.group.inviteUserName}
                                                                    imageUrl={conversation.group.inviteUserImageUrl}
                                                                    hideText
                                                                    personalName
                                                                    size={circleLarge}
                                                                />
                                                            </div>
                                                            <GroupInvitationNotification
                                                                notification={
                                                                    {
                                                                        id: 0,
                                                                        objectId: 0,
                                                                        imageUrl: "",
                                                                        date: new Date(),
                                                                        isRead: true,
                                                                        isVideo: false,
                                                                        type: 1,
                                                                        url: "groups/" + conversation.group.id,
                                                                        groupName: conversation.group.name,
                                                                        userName: conversation.group.inviteUserName,
                                                                        userId: conversation.group.inviteUserId,
                                                                        inviteId: conversation.group.membershipId,
                                                                    } as Spintr.IGroupInvitationNotification
                                                                }
                                                                history={this.props.history}
                                                                dispatch={this.props.dispatch}
                                                            />
                                                        </div>
                                                    )
                                                ))
                                            }
                                        </div>
                                    )}
                                    {this.props.conversations.some(conversation => conversation.isPinned) && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="body-3"
                                                color="mid-grey"
                                                weight="medium"
                                            >
                                                {localize("FastaGrupper")}
                                            </Label>
                                            {this.props.conversations.map((conversation, index: number) => (
                                                conversation.isPinned && (
                                                    <div
                                                        className="conversation"
                                                        key={conversation.id + "_" + index}
                                                    >
                                                        <ConversationItem
                                                            isGroupItem
                                                            conversation={conversation}
                                                            currentUserId={this.props.currentUser.id}
                                                            onClick={this.onConversationClick}
                                                            location={this.props.location}
                                                        />
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    )}
                                    {this.props.conversations.some(conversation => !conversation.isPinned && conversation.group.membershipStatus != SpintrTypes.GroupMembershipStatus.Invited) && (
                                    <div className="ConversationsPanel-segment">
                                        <Label
                                            as="div"
                                            className="ConversationsPanel-subline"
                                            size="body-3"
                                            color="mid-grey"
                                            weight="medium"
                                        >
                                            {localize("MinaGrupper")}
                                        </Label>
                                            {this.props.conversations.map((conversation, index: number) => (
                                                !conversation.isPinned && conversation.group.membershipStatus != SpintrTypes.GroupMembershipStatus.Invited && (
                                                    <div
                                                        className="conversation"
                                                        key={conversation.id + "_" + index}
                                                    >
                                                        <ConversationItem
                                                            isGroupItem
                                                            conversation={conversation}
                                                            currentUserId={this.props.currentUser.id}
                                                            onClick={this.onConversationClick}
                                                            location={this.props.location}
                                                        />
                                                    </div>
                                                )
                                            ))}
                                    </div>
                                    )}
                                </>
                            )
                        }
                        {this.props.fetchType !== SpintrTypes.ConversationsFetchType.Groups && this.props.conversations.map((conversation, index: number) => (
                            <div
                                className="conversation"
                                key={conversation.id + "_" + index}
                            >
                                <ConversationItem
                                    conversation={conversation}
                                    currentUserId={this.props.currentUser.id}
                                    onClick={this.onConversationClick}
                                    location={this.props.location}
                                />
                            </div>
                        ))}
                        {
                            this.props.fetchType === SpintrTypes.ConversationsFetchType.Groups &&
                            this.state.allGroups.hasBeenFetched &&
                            this.state.allGroups.items &&
                            this.state.allGroups.items.length > 0 && (
                                <div className="ConversationsPanel-segment">
                                    <Label
                                        as="div"
                                        className="ConversationsPanel-subline"
                                        size="small-1"
                                        color="mid-grey"
                                        weight="medium">
                                        {localize("AllaGrupper")}
                                    </Label>
                                    {this.state.allGroups.items.map((item: any) => (
                                        <div
                                            className="conversation"
                                            key={"ag" + item.id}
                                        >
                                            <GroupConversationItem
                                                item={item}
                                                updateItem={(updatedItem) => {
                                                    this.setState({
                                                        allGroups: {
                                                            ...this.state.allGroups,
                                                            items: this.state.allGroups.items.map((oldItem: any) => {
                                                                if (oldItem.id === updatedItem.id) {
                                                                    return updatedItem;
                                                                } else {
                                                                    return oldItem;
                                                                }
                                                            })
                                                        }
                                                    });
                                                }}
                                                refreshList={() => {
                                                    this.fetchAllGroups();
                                                }}
                                                displayMembersForGroup={(i: any) => {
                                                    this.setState({
                                                        displayMembersForGroup: i
                                                    });
                                                }} />
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </InfiniteScroll>
                </div>
                {
                    !!this.state.displayMembersForGroup ?
                        this.renderMembersModal() :
                        null
                }
            </div>
        );
    }

    closeMembersModal = () => {
        this.setState({
            displayMembersForGroup: null
        });
    }

    renderMembersModal() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={!!this.state.displayMembersForGroup}
                onDismiss={this.closeMembersModal}
                containerClassName={"GroupList-MembersModal"}
            >
                <PopupHeader
                    text={localize("MedlemmarI") + " " + this.state.displayMembersForGroup.name}
                    onClose={this.closeMembersModal} />
                <SpintrList
                    // ref={this.membersListRef}
                    disableSearch={true}
                    disableCommandBar={true}
                    hideHeader={true}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api.get(`/api/v1/groupmembers`, {
                                params: {
                                    groupId: this.state.displayMembersForGroup.id,
                                    isAscending: isAscending,
                                    orderByColumn: "instanceId",
                                    phrase: searchQuery,
                                    take: take,
                                    skip: skip,
                                },
                            }).then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data.members,
                                    totalCount: response.data.total
                                });
                            });
                        });
                    }}
                    columns={[
                        {
                            key: 5,
                            onRender: (item) => {
                                let subText = item.instanceId === 1 ?
                                    localize("Administrator") :
                                    item.instanceId === 2 ?
                                        localize("Medlem") :
                                        localize("Extern");

                                return <SpintrUser
                                    imageUrl={item.imageUrl}
                                    subText={subText}
                                    name={item.name}
                                    personalName={true} />;
                            },
                        }
                    ]}
                    orderByColumn={"name"}
                />
            </Modal>
        )
    }

    protected onConversationClick(conversation: Conversation): void {
        if (this.state.fetchType === SpintrTypes.ConversationsFetchType.Groups) {
            if (!!conversation.group) {
                this.props.history.push("/groups/" + conversation.group.id);
            }

            return;
        }

        if (!!this.props.openChatTabOnClick) {
            if (conversation.id === 0) {
                createConversation(conversation.participants).then((response) => {
                    const c = new Conversation(response.data);

                    this.props.dispatch(addRemoteConversation(c));
                    this.props.dispatch(setTabState(response.data.id, true, false));
                }).catch(() => { });
            } else {
                this.props.dispatch(setTabState(conversation.id, true, false));
            }

            if (this.props.onConversationClick) {
                this.props.onConversationClick();
            }

            return;
        }

        if (conversation.id === 0) {
            createConversation(conversation.participants).then((response) => {
                const c = new Conversation(response.data);

                this.props.dispatch(addRemoteConversation(c));
                this.props.history.push("/messages/" + response.data.id);
            }).catch(() => { });

            return;
        }

        this.props.history.push("/messages/" + conversation.id);
    }

    // protected onSearchChange(query: string) {
    //     if (!query || query.length === 0) {
    //         if (this.searchSubscription) {
    //             this.searchSubscription.unsubscribe();
    //             this.searchSubscription = null;
    //         }

    //         this.setState({
    //             isLoadingSearch: false,
    //             isSearching: false,
    //             searchItems: []
    //         });
    //         return;
    //     }

    //     const params: IGetConversationsParams = {
    //         fetchType: SpintrTypes.ConversationsFetchType.Users,
    //         filter: query,
    //     };

    //     this.setState(
    //         { isSearching: true, isLoadingSearch: true },
    //         () => {
    //             if (this.searchSubscription) {
    //                 this.searchSubscription.unsubscribe();
    //             }

    //             this.searchSubscription = from(getConversations(params))
    //                 .subscribe({
    //                     error: this.onSearchError,
    //                     next: this.onSearchSuccess,
    //                 });
    //         }
    //     );
    // }

    // protected onSearchError(): void {
    //     this.setState({
    //         isLoadingSearch: false,
    //         searchItems: []
    //     });
    // }

    // protected onSearchSuccess(response: [number, Spintr.IConversation[], number]): void {
    //     const [_, conversations] = response;

    //     this.setState({
    //         isLoadingSearch: false,
    //         searchItems: conversations.map((c) => Conversation.createLocal(c)),
    //     });
    // }
};

const mapStateToProps: MapStateToProps<IStateProps, OwnProps, Spintr.AppState> =
    (state, props) => ({
        isLoading: state.chat.conversations.isLoading,
        hasMore: state.chat.conversations.hasMoreFetchTypes[props.fetchType],
        currentUser: state.profile.active,
        conversations: state.chat.conversations.items
            .filter(c => (!!props.fetchType && props.fetchType === SpintrTypes.ConversationsFetchType.Groups) ? c.type === 3 : c.type !== 3)
            .sort(
                (a, b) => {
                    if (!a.lastMessage || !a.lastMessage.date) {
                        return 1;
                    }

                    if (!b.lastMessage || !b.lastMessage.date) {
                        return -1;
                    }

                    const aDate = typeof a.lastMessage.date === "string"
                        ? new Date(a.lastMessage.date)
                        : a.lastMessage.date;

                    const bDate = typeof b.lastMessage.date === "string"
                        ? new Date(b.lastMessage.date)
                        : b.lastMessage.date;

                    return (
                        bDate.getTime()
                        - aDate.getTime()
                    );
                }
            ),
        hasLoadedConversations: state.chat.conversations.hasFetchedFetchTypes
            .indexOf(props.fetchType) > -1,
        displayAsOfflineInChat: state.ui.displayAsOfflineInChat,
        unreadEmails: state.profile.unreadEmails,
        googleConnected: state.profile.active.googleConnected,
        office365Connected: state.profile.active.office365Connected,
        exchangeWebmailUrl: state.instance.get("exchangeWebmailUrl"),
        disableGroupCreation: state.instance.get("disableGroupCreation") || !state.profile.active.rights.hasAccessToGroups || (state.instance.get("restrictGroups") && !state.profile.active.isAdmin && !state.profile.active.isEditor && !state.profile.active.settings.canCreateGroups),
        emailEnabled:
            state.instance.get("enableExternalMailLink") != "false" &&
            (state.profile.active.googleConnected ||
                (state.profile.active.roles.includes("aduser") &&
                    (state.instance.get("enableExchange") ||
                        (state.profile.active.office365Connected && state.instance.get("office365Enabled"))))),
        allowAdministratorsAccessToAllGroups: state.profile.active.isSpintrAdminAccount ||
            (state.instance.get("allowAdministratorsAccessToAllGroups") && state.profile.active.isAdmin)
    });

const ConversationsPanelWithRouter = withRouter(ConversationsPanel);

const ConnectedConversationsPanelWithRouter =
    connect(mapStateToProps)(ConversationsPanelWithRouter);

export default ConnectedConversationsPanelWithRouter;