// tslint:disable-next-line: no-namespace
export module SpintrTypes {
    /* * * * *
     * Enums *
     * * * * */
    export enum SpintrApp {
        Media = 1,
        Blogs = 2,
        Absence = 3,
        Forms = 4,
        News = 5,
        Calendars = 6,
        Files = 7,
        Organisation = 8,
        Wikis = 9,
        Booking = 10,
        RSS = 11,
        Birthdays = 13,
        Tags = 14,
        EmergencyAlerts = 15,
        Newsletters = 16,
        Todos = 17,
        Instagram = 18,
        Competence = 19,
        DeviationManager = 20,
        ImprovementsSuggestions = 21,
        Dashboard = 22,
        OperatingInfo = 23,
        Newsletter = 24,
        Notes = 25,
        QA = 26,
        ActivityLog = 27,
        Suppliers = 28,
        Slack = 29,
        Coworkers = 30,
        Academy = 32,
        Products  = 33,
    }

    export enum CompletionType {
        Expansion = 1, // Expects length
        Correction = 2,
        Reformulation = 3,
        Summarization = 4, // Expects type (list, sentence, paragraph)
        TonalityChange = 5, // Expects tone (friendly, professional, funny, warm, educational)
        HeadingSuggestion = 6,
        IntroductionSuggestion = 7
    }    

    export enum LayoutType {
        StartPage = 1,
    }

    export enum ContentStatus {
        Published = 1,
        Draft = 2,
        Deleted = 4,
        Expired = 8
    }

    export const SpintrAppInfo = {
        Media: { moduleKey: "gallerytop", icon: "Photo2", id: 1 },
        Blogs: { moduleKey: "blogtop", icon: "Blog", id: 2 },
        Absence: { moduleKey: "absence", icon: "Vacation", id: 3 },
        Forms: { moduleKey: "", icon: "Questionnaire", id: 4 },
        News: { moduleKey: "news", icon: "News", id: 5 },
        Calendars: { moduleKey: "calendar", icon: "Calendar", id: 6 },
        Files: { moduleKey: "files", icon: "FileSystem", id: 7 },
        Organisation: { moduleKey: "departmenttop", icon: "EngineeringGroup", id: 8 },
        Wikis: { moduleKey: "wikis", icon: "BulletedTreeList", id: 9 },
        Booking: { moduleKey: "bookings", icon: "Car", id: 10 },
        RSS: { moduleKey: "", icon: "ViewList", id: 11 },
        Birthdays: { moduleKey: "", icon: "BirthdayCake", id: 13 },
        Tags: { moduleKey: "", icon: "TagGroup", id: 14 },
        EmergencyAlerts: { moduleKey: "", icon: "ReportAlert", id: 15 },
        Newsletters: { moduleKey: "", icon: "MailSchedule", id: 16 },
        Todos: { moduleKey: "todo", icon: "CheckList", id: 17 },
        Instagram: { moduleKey: "", icon: "Photo2", id: 18 },
        Competence: { moduleKey: "", icon: "KeyboardClassic", id: 19 },
        DeviationManager: { moduleKey: "", icon: "KeyboardClassic", id: 20 },
        ImprovementsSuggestions: { moduleKey: "", icon: "KeyboardClassic", id: 21 },
        Dashboard: { moduleKey: "", icon: "GoToDashboard", id: 22 },
        OperatingInfo: { moduleKey: "operatinginfo", icon: "AlertSolid", id: 23 },
        Newsletter: { moduleKey: "", icon: "MailSchedule", id: 24 },
        Notes: { moduleKey: "notes", icon: "EditNote", id: 25 },
        QA: { moduleKey: "qa", icon: "QandA", id: 26 },
        ActivityLog: { moduleKey: "activitylog", icon: "AnalyticsLogo", id: 27 },
        Suppliers: { moduleKey: "suppliers", icon: "Suitcase", id: 28 },
        Slack: { moduleKey: "", icon: "CannedChat", id: 29 },
    }

    export enum WidgetType {
        Any = 0,
        KeyPointIndicator = 1,
        LineChart = 2,
        BarChart = 3,
        PieChart = 4,
        CircularChart = 5,
        List = 6,
        ListOneColumn = 7,
        GagueChart = 8
    }

    export enum DataAppAuthenticationMethod {
        AnonymousAccess = 1,
        BasicAuthentication = 2,
        OAuth2ClientCredentials = 3,
        CustomHeader = 4,
        CustomQueryString = 5,
        OAuth1ClientCredentials = 6,

        SpintrKPI = 101,
        Fortnox = 102,
        Teamtailor = 103,
        Learnster = 106
    }

    export enum HeaderSearchBarMode {
        Search = 1,
        Assistant = 2,
    }

    export enum Language {
        Swedish         = 1,
        Norweigan       = 2,
        English         = 3,
        German          = 4,
        Finnish         = 5,
        Danish          = 6,
        Italian         = 7,
        Spanish         = 8,
        French          = 9,
    }

    export enum StatusFrameFonts {
        PageDefault = 1,
        Merriweather = 2,
        IndieFlower = 3,
        Pacifico = 4,
    }

    export enum UberType {
        Unknown = 0,
        User = 1,
        Department = 2,
        Status = 3,
        Bookmark = 4,
        Like = 5,
        Comment = 6,
        File = 7,
        Office = 8,
        TextPage = 9,
        NewsArticle = 10,
        Event = 11,
        Gallery = 12,
        Image = 13,
        ImageTag = 14,
        Wiki = 15,
        WikiArticle = 16,
        WikiSection = 17,
        WikiDiscussion = 18,
        WikiSectionBackup = 19,
        Blog = 20,
        BlogPost = 21,
        UserSkill = 22,
        UserInterest = 23,
        UserEmployer = 24,
        FileVersion = 25,
        Group = 26,
        GroupMember = 27,
        Milestone = 28,
        Task = 29,
        CRM = 30,
        CRMNote = 31,
        CRMTodo = 32,
        Poll = 33,
        PollOption = 34,
        PollAnswer = 35,
        UserEducation = 36,
        Conversation = 37,
        RssEntry = 38,
        MSRoutineCopy = 39,
        MSDeviation = 40,
        MSRoutine = 41,
        FileExtra = 42,
        MSAction = 43,
        Role = 44,
        BookingResource = 45,
        TextPageUpdate = 46,
        Survey = 47,
        EmergencyMessage = 48,
        ImagePost = 49,
        CaneaFile = 50,
        TodoList = 51,
        TargetGroup = 52,
        TodoDepartmentChecked = 53,
        HiddenPost = 54,
        QA = 55,
        Note = 56,
        TeaserBox = 57,
        FileCategory = 60,
        PlanningCategory = 61,
        PlanningItemTodo = 63,
        Shortcut = 62,
        PlanningItem = 64,
        Company = 65,
        ChatMessage = 70,
        UserEmployment = 71,
        StoryFeed = 72,
        StoryItem = 73,
        ExternalFile = 74,
        Supplier = 75,
        OperatingInfo = 76,
        SupplierGroup = 77,
        SupplierExternalMessage = 78,
        SupplierRoleData = 79,
        SupplierExternalFile = 80,
        BusinessDeal = 81,
        SomethingFun = 82,
        Kudos = 83,

        Product = 88,
    }

    export enum Privilege {
        CreatePages = 1001,
        CreateSubPages = 1002,
        EditPage = 1003,
        DeletePage = 1004,
        CreateAndEditNewsArticles = 2001,
        CanViewFileCategory = 3001
    }

    export enum StatusType {
        Text = 0,
        Video = 1,
        Share = 2,
        Bookmark = 3,
        Frame = 4,
        File = 5,
        Question = 6,
        SomethingFun = 7,
        BusinessDeal = 8,
        Kudos = 9
    }

    export enum StatusFrameBackground {
        Gradient = 1,
        Image = 2,
    }

    export enum StatusFrameGradientDirection {
        Top = 1,
        Right = 2,
        Bottom = 3,
        Left = 4,
    }

    export enum GalleryType {
        Central = 0,
        Public = 1,
        Personal = 2,
        Mobile = 3,
        Profile = 4,
        Feed = 5,
        Project = 6,
        Unit = 7,
        Video = 8,
        Instagram = 9,
        CustomAudience = 10,
    }

    export enum ConversationType {
        SinglePerson = 1,
        MultiplePeople = 2,
        Group = 3,
    }

    export enum InformationFeedTemplate {
        NotSet = 0,
        Small = 1,
        Medium = 2,
        Large = 3,
        OnlyText = 4,
    }

    export enum ViewMode {
        Unset = 0,
        Small = 1,
        Medium = 2,
        Large = 3,

        PhonePortrait = 320,
        TabletPortrait = 768,
        PhoneLandscape = 960,
        TabletLandscape = 1024,
        SmallestLaptop = 1200,
        SmallLaptop = 1366,
        MediumLaptop = 1440,
        FullSize = 1560,
    }

    export enum BlogPostType {
        Image = 1,
        Video = 2,
        Podcast = 3,
    }

    export enum NewsFeedType {
        BigNewsArticles = 1,
        SmallNewsArticles = 2,
        ListView = 3
    }

    export enum MediaFileType {
        Image = 0,
        Video = 1,
        Audio = 2,
    }

    export enum FileStatus {
        Uploading = 0,
        Uploaded = 1,
        Encoding = 2,
        Done = 3,
        Error = 4,
        Undefined = 5,
    }

    export enum ConversationsFetchType {
        All = 1,
        Users = 2,
        Groups = 3,
    }

    export enum EventCategory {
        None = 0,
        Previous = 1,
        Upcoming = 2,
        Attending = 3,
    }

    export enum CalendarType {
        Public = 0,
        Project = 1,
        Personal = 2,
        Department = 3,
        Object = 4,
        Outlook = 5,
        Google = 6,
    }

    export enum UnitType {
        Office = 1,
        Department = 2,
        Company = 3,
    }

    export enum FileType {
        Undefined = 1,
        Pdf = 2,
        Mp3 = 3,
        Jpg = 4,
        Xls = 5,
        Xlsx = 6,
        Doc = 7,
        Docx = 8,
        Png = 9,
        Ppt = 10,
        Pptx = 11,
        Zip = 12,
        Gif = 13,
        Wav = 14,
        Rtf = 15,
        Html = 16,
        Txt = 17,
        Csv = 18,
        Pps = 19,
        Ods = 20,
        Odt = 21,
        Odp = 22,
        Sxw = 23,
        Sxc = 24,
        Sxi = 25,
    }

    export enum StartPageBuilderMode {
        Edit = 1,
        DesktopPreview,
        ResponsivePreview
    }

    export enum FolderRootSource {
        Upload = 0,
        SpintrPublic = 1,
        SpintrPrivate = 2,
        SpintrGroup = 3,
        Office365Public = 4,
        Office365Private = 5,
        Office365Group = 6,
        GoogleDrive = 7,
        SharePointSearch = 9,
        Canea = 10,
    }

    export enum FeedType {
        Unknown = 0,
        General = 1,
        User = 2,
        Group = 3,
        Dump = 4,
        Unused1 = 5,
        Unit = 6
    }

    export enum GroupFunction {
        Chat = 0,
        Planning = 1,
        Notes = 2,
        Files = 3,
        Calendar = 4,
        Members = 5,
        Feed = 6,
        Support = 10,
    }

    export enum UberContentSectionState {
        IsDisplayed = 0,
        IsEditing = 1,
        IsSelectingType = 2,
        IsDisplayingPlusSign = 3
    }

    export enum UberContentSectionType {
        Text = 0,
        Image = 1,
        IFrame = 2,
        Graph = 3,
        Form = 4,
        File = 5,
        DataWidget = 6,
        TeaserBox = 7,
        Calendar = 8,
        ExpandableField = 9,
        Link = 10,
        Direkten = 11,
        Preamble = 12,
        UserList = 13,
        Products = 14,
        TaxiFiles = 15,
        Video = 16,
        AcademyQuestion = 17,
        AcademyTask = 18,
    }

    export enum TeaserBoxType {
        HeadingAndText = 1,
        HeadingAndIcon = 2,
        HeadingOnly = 3,
        Blog = 4,
        SjWidget = 5,
        Iframe = 6,
        DataWidget = 7,
        ImageOnly = 8
    }
    export enum TeaserBoxFilter {
        All = 0,
        TeaserBoxes = 1,
        DataWidgets = 2,
        Blogs = 3,
        TextPages = 4,
        Shortcuts = 5,
        StartPage = 6
    }

    export enum TeaserBoxPosition {
        Shortcuts = 0,
        StartPage = 1,
        TextPages = 2
    }

    export enum BonusFileResultType {
        FileUploaded = 1,
        FileAlreadyExists = 2,
        FolderNotFound = 3,
        InvalidFileName = 4,
    }

    export enum RealtimeEventName {
        CalendarEventUpdated = "CalendarEvent:Updated",

        ChatMessageDeleted = "Chat.Message:Deleted",
        ChatMessageHasRead = "Chat.Message:HasRead",
        ChatMessageNew = "Chat.Message:New",
        ChatMessagePinned = "Chat.Message:Pinned",
        ChatMessageReaction = "Chat.Message:Reaction",
        ChatMessageUnpinned = "Chat.Message:Unpinned",
        ChatMessageUnreaction = "Chat.Message:Unreaction",

        CommentNew = "Comment:New",
        CommentUpdated = "Comment:Updated",

        ConversationLastRead = "Conversation.LastRead",

        EmergencyMessageNew = "EmergencyMessage:New",
        EmergencyMessageUpdated = "EmergencyMessage:Updated",

        FeedPostCreated = "Feed:Post:Created",
        FeedPostUpdated = "Feed:Post:Updated",

        GroupPlanningUpdated = "Group:Planning:Updated",
        GroupPlanningItemRemoved = "Group:PlanningItem:Removed",

        LikeDeleted = "Like:Deleted",
        LikeNew = "Like:New",

        ObjectDelete = "Object:Delete",
        ObjectPin = "Object:Pin",
        ObjectHideToggle = "Object:HideToggle",

        OperatingInfoNew = "OperatingInfo:New",
        OperatingInfoUpdated = "OperatingInfo:Updated",

        PollVote = "Poll:Vote",
    }

    export enum KeyCodes {
        Backspace = 8,
        Tab = 9,
        Enter = 13,
        Shift = 16,
        Ctrl = 17,
        Alt = 18,
        Pause = 19,
        CapsLock = 20,
        Escape = 27,
        Space = 32,
        PageUp = 33,
        PageDown = 34,
        End = 35,
        Home = 36,
        LeftArrow = 37,
        UpArrow = 38,
        RightArrow = 39,
        DownArrow = 40,
        Insert = 45,
        Delete = 46,

        Digit0 = 48,
        Digit1 = 49,
        Digit2 = 50,
        Digit3 = 51,
        Digit4 = 52,
        Digit5 = 53,
        Digit6 = 54,
        Digit7 = 55,
        Digit8 = 56,
        Digit9 = 57,

        a = 65,
        b = 66,
        c = 67,
        d = 68,
        e = 69,
        f = 70,
        g = 71,
        h = 72,
        i = 73,
        j = 74,
        k = 75,
        l = 76,
        m = 77,
        n = 78,
        o = 79,
        p = 80,
        q = 81,
        r = 82,
        s = 83,
        t = 84,
        u = 85,
        v = 86,
        w = 87,
        x = 88,
        y = 89,
        z = 90,

        LeftWindowLey = 91,
        RightWindowKey = 92,
        SelectKey = 93,

        Numpad0 = 96,
        Numpad1 = 97,
        Numpad2 = 98,
        Numpad3 = 99,
        Numpad4 = 100,
        Numpad5 = 101,
        Numpad6 = 102,
        Numpad7 = 103,
        Numpad8 = 104,
        Numpad9 = 105,
        Multiply = 106,
        Add = 107,
        Subtract = 109,
        DecimalPoint = 110,
        Divide = 111,

        F1 = 112,
        F2 = 113,
        F3 = 114,
        F4 = 115,
        F5 = 116,
        F6 = 117,
        F7 = 118,
        F8 = 119,
        F9 = 120,
        F10 = 121,
        F11 = 122,
        F12 = 123,

        NavigationLeft = 140,
        NavigationRight = 141,
        NavigationAccept = 142,
        NavigationUp = 138,
        NavigationDown = 139,

        NumLock = 144,
        ScrollLock = 145,
        SemiColon = 186,
        EqualSign = 187,
        Comma = 188,
        Dash = 189,
        Period = 190,
        ForwardSlash = 191,
        GraveAccent = 192,
        OpenBracket = 219,
        BackSlash = 220,
        CloseBraket = 221,
        SingleQuote = 222,
    };

    export enum ImageCropAspect {
        Wide = 2.95,
        UltraWide = 1000/170,
    }

    export enum InsightsType {
        Question = 1,
        SearchRelated = 2,
        SearchExpert = 3,
        SearchRecommended = 4,
        OpenedShortcuts = 5,
        ClickedShortcut = 6,
        ClickedTeaserbox = 7,
        OpenedOperatingInfo = 8,
        VisitedNewsArchive = 9,
        VisitedTeamsApp = 10
    }

    export enum LoginResponse {
        Success = 1,
        Error = 2,
        LockedOut = 3,
        FirstTime = 4,
        FirstTimeAD = 5,
        AuthenticatorRequired = 6,
    }

    export enum AuthenticationMechanisms {
        RFC6238TOTP = 1,
    }

    export enum QuestionType
    {
        FreeText = 1,
        SimpleAnswer = 2,
        MultipleAnswer = 3,
        Scale = 4,
        File = 5,
        Date = 6,
        Description = 7,
    }

    export enum TranslateType {
        Undefined = 0,
        Title = 1,
        Content = 2,
        Preamble = 3,
        CommentPreview = 4,
        ExtraId = 5,
    }

    export enum GroupMembershipStatus {
        None = 0,
        Invited = 1,
        Member = 2,
        Administrator = 3,
    }

    export enum ReportType {
        Uber = 1,
        ChatMessage = 2,
    }

    export enum TranslationError {
        Failed = 0,
        RequestLimitExceeded = 1
    }

    export enum UberDirectType {
        None = 0,
        Hidden = 1,
        Factchecker = 2,
        Approver = 3,
        Follower = 4,
        Publisher = 5,
        TeaserBoxDirect = 6,
        Editor = 7,
        ArticleManager = 8
    }
}
