import React, { useEffect, useState } from 'react';
import { DefaultButton } from '@fluentui/react';
import { localize } from 'src/l10n';
import { Label, UnstyledButton } from 'src/ui';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import "./CalypsoPromoBanner.scss";

interface IProps {
    isAdminOrEditor: boolean;
    appMode: boolean;
};

const CalypsoPromoBanner = (props: IProps) => {
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [localStorageId] = useState<string>("spintr_hasSeenPromoBanner");

    useEffect(() =>  {
        const hasSeenPromoBanner = localStorage.getItem(localStorageId) === "true";

        if (props.isAdminOrEditor && !props.appMode && !hasSeenPromoBanner) {
            setShowBanner(true);
        }
    }, [props.isAdminOrEditor]);

    if (!showBanner) {
        return null;
    }

    return (
        <div className="CalypsoPromoBanner">
            <div className="CalypsoPromoBanner-inner">
                <div className="main">
                    <div className="icon-wrapper">
                        <ReactSVG src={"/images/SomethingFun_party.svg"} />
                    </div>
                    <div className="content">
                        <Label size="body-1" color="white" weight="medium">{localize("PROMO_TITLE")}</Label>
                        <Label size="body-2" color="white">{localize("PROMO_MESSAGE")}</Label>
                    </div>
                </div>
                <div className="buttons">
                    <UnstyledButton onClick={() => {
                        setShowBanner(false);
                        localStorage.setItem(localStorageId, "true");
                    }}>
                        <Label color="white">{localize("Stang")}</Label>
                    </UnstyledButton>
                    {/* <DefaultButton onClick={this.closeBanner}>{localize("Stang")}</DefaultButton> */}
                    <DefaultButton onClick={() => {
                        window.open("https://www.spintr.com/calypso", "_blank");
                        setShowBanner(false);
                    }}>{localize("OPEN_WEBINAR")}</DefaultButton>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdminOrEditor: state.profile.active.isAdmin || state.profile.active.isEditor,
        appMode: state.ui.appMode,
    };
};

export default connect(mapStateToProps)(CalypsoPromoBanner);
