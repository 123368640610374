import React from "react";
import AcademyCourseListItem from "src/academy/courses/AcademyCourseListItem/AcademyCourseListItem";
import { IAcademyCourse } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseList.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { localize } from "src/l10n";
import { Link } from "react-router-dom";

interface IProps {
    courses: IAcademyCourse[];
    title?: string;
    big?: boolean;
    canCreateCourses?: boolean;
    isFirstCourse?: boolean;
}

const AcademyCourseList = (props: IProps) => {
    return (
        <div className="AcademyCourseList">
            {props.title && (
                <Label size="h5" weight="medium">{props.title}</Label>
            )}
            <div className="courses">
                {props.courses.map((x: IAcademyCourse) => {
                    return (
                        <AcademyCourseListItem key={x.id} course={x} big={props.big} />
                    )
                })}
                {props.title === localize("NOT_STARTED_PLURAL") && props.canCreateCourses && (
                    <Link className="AcademyCourseListItem create" to="/admin/academy/create">
                        <Visage2Icon icon="add" color="visageMidBlue" size="big" />
                        <Label weight="bold" color="dark-grey">
                            {localize(props.isFirstCourse ? "CREATE_FIRST_COURSE" : "CREATE_A_COURSE")}
                        </Label>
                        {props.isFirstCourse && (
                            <Label color="dark-grey">{localize("CREATE_FIRST_COURSE_DESC")}</Label>
                        )}
                    </Link>
                )}
            </div>
        </div>
    )
}

export default AcademyCourseList;
