import { List, Map } from 'immutable';
import { Icon as FabricIcon, Icon } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { SpintrTypes } from "src/typings";
import { Label, setResponsiveMenuVisibleAction, UnstyledButton } from "src/ui";
import OperatingInfoNotification from "../OperatingInfoNotification";
import { ResponsiveLinks } from '../ResponsiveLinks';
import "./ResponsiveMenu.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { fetchMainMenu } from 'src/menu/actions';

interface IOwnProps {

}

interface IStateProps {
    intranetTitle: string;
    disablePersonChat?: boolean;
    isVisible: boolean;

    menuItems: Spintr.IMainMenuItem[];

    operatingInfoEnabled: boolean;
    coworkersEnabled: boolean;
    groupsEnabled: boolean;

    unreadGroupChats: number;
    unreadNonGroupChats: number;
    unreadNotifications: number;
}

interface IDispatchProps {
    setResponsiveMenuVisibleAction: (visible: boolean) => void;
    fetchMainMenu: () => void;
}

type Props = IDispatchProps & IOwnProps & IStateProps & RouteComponentProps;

const ResponsiveMenu: FunctionComponent<Props> = (props) => {
    useEffect(
        () => { props.setResponsiveMenuVisibleAction(false); },
        [props.location.pathname],
    );

    useEffect(() => {
        props.fetchMainMenu();
    }, []);

    const onCloseClick = useCallback(
        () => props.setResponsiveMenuVisibleAction(false),
        [props.setResponsiveMenuVisibleAction],
    );

    const renderItemInner = (item) => {
        const isIcon = item.cssClass === 'icon';
        const name = item.name;

        if (isIcon) {
            return renderItemIcon(name);
        } else {
            return renderItemText(name);
        }
    }

    const renderItemIcon = (title) => {
        return (
            <Label
                as="span"
                size="h6">
                <FabricIcon iconName={title} />
            </Label>
        )
    }

    const renderItemText = (title) => {
        return (
            <Label
                as="span"
                size="h6">
                {title}
            </Label>
        )
    }

    return (
        <div id="ResponsiveMenu" className={props.isVisible ? "" : "hidden"}>
            <div className="overlay" onClick={onCloseClick} />
            <div className="menu">
                <div className="header">
                    <UnstyledButton className="close-button" onClick={onCloseClick}>
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                    <Label className="intranet-title">
                        {props.intranetTitle}
                    </Label>
                    {props.operatingInfoEnabled && (
                        <div className="operating-info">
                            <OperatingInfoNotification />
                        </div>
                    )}
                </div>
                <div className="body">
                    <div className="drawer">
                        <div className="row">
                            <div className="column">
                                <Link to="/notifications" className="link">
                                    <div className="icon">
                                        <Visage2Icon icon="notification" />
                                        {props.unreadNotifications > 0 && (
                                            <div className="unread primaryBGColor">
                                                <span className="number">
                                                    {props.unreadNotifications}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="text">
                                        <Label size="small-1">
                                            {localize("Notiser")}
                                        </Label>
                                    </div>
                                </Link>
                            </div>
                            {
                                !props.disablePersonChat && (
                                    <div className="column">
                                        <Link to="/messages" className="link">
                                            <div className="icon">
                                                <Visage2Icon icon="message" />
                                                {props.unreadNonGroupChats > 0 && (
                                                    <div className="unread primaryBGColor">
                                                        <span className="number">
                                                            {props.unreadNonGroupChats}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text">
                                                <Label size="small-1">
                                                    {localize("Chatt")}
                                                </Label>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                            {props.coworkersEnabled && (
                                <div className="column">
                                    <Link to="/people" className="link">
                                        <div className="icon">
                                            <Visage2Icon icon="personalcard" />
                                        </div>
                                        <div className="text">
                                            <Label size="small-1">{localize("Medarbetare")}</Label>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {props.groupsEnabled && (
                                <div className="column">
                                    <Link to="/my-groups" className="link">
                                        <div className="icon">
                                            <Visage2Icon icon="people" />
                                            {props.unreadGroupChats > 0 && (
                                                <div className="unread primaryBGColor">
                                                    <span className="number">
                                                        {props.unreadGroupChats}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="text">
                                            <Label size="small-1">
                                                {localize("Grupper")}
                                            </Label>
                                        </div>
                                    </Link>
                                </div>
                            )}
                            <div className="column">
                                <Link to="/sitemap" className="link">
                                    <div className="icon">
                                        <Visage2Icon icon="hierarchy" />
                                    </div>
                                    <div className="text">
                                        <Label size="small-1">
                                            {localize("Intranatskarta")}
                                        </Label>
                                    </div>
                                </Link>
                            </div>
                            <div className="column">
                                <a
                                    className="link"
                                    href="https://support.spintr.me"
                                    onClick={onCloseClick}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <div className="icon">
                                        <Visage2Icon icon="call" />
                                    </div>
                                    <div className="text">
                                        <Label size="small-1">
                                            {localize("Hjalp")}
                                        </Label>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="menu-items">
                        <ul className="list">
                            {props.menuItems.map((item, index) => {
                                const isExternal = item.url.indexOf("http") === 0;

                                if (isExternal) {
                                    return (
                                        <li key={`mainMenu:item${index}`}>
                                            <a
                                                className="primaryFGColorHover primaryBorderColorHover"
                                                title={item.title}
                                                target="_blank"
                                                href={item.url}>
                                                {
                                                    renderItemInner(item)
                                                }
                                                <Visage2Icon icon="arrow-right-3" className="chevron-icon" />
                                            </a>
                                        </li>
                                    )
                                }

                                return (
                                    <li key={`mainMenu:item${index}`}>
                                        <NavLink
                                            activeClassName="primaryFGColor primaryBorderBottomColor"
                                            className="primaryFGColorHover primaryBorderColorHover"
                                            title={item.title}
                                            to={item.url}>
                                            {
                                                renderItemInner(item)
                                            }
                                            <Visage2Icon icon="arrow-right-3" className="chevron-icon" />
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                        </ul>
                    </div>
                    {/* <div className="responsive-shortcuts">
                        <ResponsiveLinks />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        intranetTitle: state.instance.get("name") as string,
        disablePersonChat: state.instance.get('disablePersonChat'),
        isVisible: (state.ui.responsive || {}).menuVisible,

        menuItems: state.menu.main.items,

        operatingInfoEnabled: state.app.items.some(
            (app) => app.enabled && app.id === SpintrTypes.SpintrApp.OperatingInfo,
        ),

        coworkersEnabled: state.app.items.some(
            (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Coworkers,
        ),

        groupsEnabled: state.profile.active.rights.hasAccessToGroups,

        unreadGroupChats: state.chat.conversations.items.filter(c => c.type === 3 && c.unread > 0).length,
        unreadNonGroupChats: state.chat.conversations.items.filter(c => c.type !== 3 && c.unread > 0).length,
        unreadNotifications: state.notification.unread,
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    setResponsiveMenuVisibleAction,
    fetchMainMenu
};

const ResponsiveMenuWithRouter = withRouter(ResponsiveMenu);
export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveMenuWithRouter);