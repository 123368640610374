import React, { FunctionComponent, ReactElement } from 'react';
import { SocialFeedContainer, SocialFeedType } from "src/social-feed";
import { InlineComposer } from "../Composer";
import { useSelector } from 'react-redux';

import "./SpintrSocialFeed.scss";
import classNames from 'classnames';

interface IProps {
    feedId?: number;
    feedType: SocialFeedType;
    galleryId?: number;
    userId?: number;
    groupId?: number;
    identity?: any;
    isUnit?: boolean;
    isStartPage?:boolean;
    hideComposer?: boolean;
}

type StateProps = {
};

const SpintrSocialFeed: FunctionComponent<IProps> = (props: IProps): ReactElement => {
    const hideComposer = useSelector<Spintr.AppState, StateProps>((appState) => 
        appState.instance.get("restrictedSocialFeed") &&
        appState.profile.active.rights.canPostToFeed === false,
    );

    return (
        <div role="region" className={classNames("SpintrSocialFeed", {
            "without-composer": hideComposer || props.hideComposer,
        })}>
            {!props.hideComposer && !hideComposer && (
                <InlineComposer
                    feedId={props.feedId}
                    galleryId={props.galleryId}
                    identity={props.identity}
                    isUnit={props.isUnit}
                    isStartPage={props.isStartPage}
                />
            )}
            <div className="social-post-feed">
                <SocialFeedContainer 
                    feedId={props.feedId}
                    groupId={props.groupId}
                    feedType={props.feedType}
                    identity={props.identity}
                />
            </div>
        </div>
    );
};

export default SpintrSocialFeed
