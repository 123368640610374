import axios from "axios";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { capitalizeFirstLetter } from "src/utils";
import { setMenu } from "./actions";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode } from "./reducer";
import { IActiveUserProfile } from "src/profile/reducer";

let headwayScriptHasBeenAdded = false;

export const addHeadwayScript = () : Promise<void> => {
    return new Promise<void>((resolve) => {
        if (headwayScriptHasBeenAdded) {
            return resolve();
        }

        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cdn.headwayapp.co/widget.js";

        script.addEventListener("load", () => {
            headwayScriptHasBeenAdded = true;
            resolve();
        }, false);

        document.head.appendChild(script);
    });
}

export const getHeadwayCount = () : Promise<number> => {
    return new Promise((resolve) => {
        const el = document.createElement('div');

        el.style.cssText = 'opacity:0;';
        el.classList.add("Changelog");

        document.body.appendChild(el);

        const HW_config = {
            selector: ".Changelog", // CSS selector where to inject the badge
            account: "xbZ41x",
            trigger: ".Changelog",
            callbacks: {
                onWidgetReady: (widget: any) => {
                    const count = widget.getUnseenCount();

                    el.remove();

                    resolve(count);
                }
            }
        }

        addHeadwayScript().then(() => {
            //@ts-ignore
            if (window.Headway) {
                //@ts-ignore
                window.Headway.init(HW_config);
            }
        }).catch(() => {});
    });
}

const getMenuItemRoutes = (item) => {
    let result = [];
    let url = item.url;

    if (!!url) {
        result.push(url);
    }

    const complementaryRoutes = [
        ["/bokning", "/booking"],
        ["/bloggar", "/blogs"],
        ["/nyheter", "/news"],
        ["/grupper", "/groups"],
        ["/handelser", "/posts"],
        ["/profil", "/profile"],
        ["/avdelningar", "/organisation"],
        ["/kalender", "/calendar"],
        ["/filer", "/files"],
        ["/mitt-kontor", "/my-office"],
        ["/min-avdelning", "/my-unit"],
        ["/min-enhet", "/my-unit"],
        ["/content-tool", "/admin"],
        ["/kontakter", "/people"],
        ["/profilinstallningar", "/profile-settings"],
    ]

    for (let routes of complementaryRoutes) {
        if (routes.indexOf(url) > -1) {
            result = routes;
        }
    }

    return result;
}

const getModuleKeys = () => {
    let apps = [
        { icon: "card-edit", activeIcon: "VisageSidebarAppGenericFilled", moduleKey: "blogtop" },
        { icon: "calendar-circle", activeIcon: "VIsageSidebarCarFilled", moduleKey: "bookings" },
        { icon: "Folder", activeIcon: "VisageSidebarFolderFilled", moduleKey: "files" },
        { icon: "gallery", activeIcon: "VisageSidebarImageFilled", moduleKey: "gallerytop" },
        { icon: "firstline", activeIcon: "VisageSidebarNewsFilled", moduleKey: "news" },
        { icon: "hierarchy", activeIcon: "VisageSidebarOfficeFilled", moduleKey: "departmenttop" },
        { icon: "hierarchy", activeIcon: "VisageSidebarOfficeFilled", moduleKey: "kontordir" },
        { icon: "hierarchy", activeIcon: "VisageSidebarOfficeFilled", moduleKey: "departmentparent" },
        { icon: "message-question", activeIcon: "VisageSidebarHelpFilled", moduleKey: "qa" },
        { icon: "hierarchy-square", activeIcon: "VisageSidebarSuppliersFilled", moduleKey: "suppliers" },
        { icon: "book", activeIcon: "VisageSidebarWikiFilled", moduleKey: "wikitop" },
        { icon: "people", activeIcon: "VisageSidebarGroupFilled", moduleKey: "project" },
        { icon: "personalcard", activeIcon: "VisageSidebarPeopleFilled", moduleKey: "people" },
        { icon: "document", activeIcon: "VisageSidebarPageFilled", moduleKey: "textpage_" },
        { icon: "briefcase", activeIcon: "briefcase", moduleKey: "payroll" },
        { icon: "shopping-cart", activeIcon: "shopping-cart", moduleKey: "ProductsFeature"},
        { icon: "teacher", activeIcon: "teacher", moduleKey: "academy" },
    ];

    return apps;
}

const getModuleKeyIcon = (moduleKey?: string) => {
    if (!moduleKey) {
        return null;
    }

    const apps = getModuleKeys();

    const app = apps.find((m: any) => moduleKey.indexOf(m.moduleKey) > -1);

    if (!app) {
        return null;
    }

    return app.icon;
}

const getModuleKeyActiveIcon = (moduleKey?: string) => {
    if (!moduleKey) {
        return null;
    }

    const apps = getModuleKeys();

    const app = apps.find((m: any) => moduleKey.indexOf(m.moduleKey) > -1);

    if (!app || !app.activeIcon) {
        return null;
    }

    return app.activeIcon;
}

const formatMenuItem = (item: any) => {
    let formattedItem: IVisageSidebarMenuItem = {
        color: item.color,
        imageUrl: item.imageUrl,
        key: item.id,
        name: item.name ? item.name : item.title,
        routes: getMenuItemRoutes(item),
        route: item.url,
        moduleKey: item.moduleKey,
        icon: getModuleKeyIcon(item.moduleKey),
        activeIcon: getModuleKeyActiveIcon(item.moduleKey),
        activeMode: VisageSidebarMenuItemActiveMode.exact,
        children: []
    };

    if (item.nodes && item.nodes.length > 0) {
        for (let node of item.nodes) {
            formattedItem.children.push(formatMenuItem(node));
        }
    }

    return formattedItem;
}

export function fetchMenu(dispatch: any, currentUser: IActiveUserProfile, instance?: any, apps?: Spintr.ISystemApp[]) {
    api.get('/api/menu/top').then((response) => {
        dispatch(setMenu(formatMainMenu(response.data, currentUser, instance, apps)));
    }).catch(() => { });
}

export function formatMainMenu(items: any, currentUser: IActiveUserProfile, instance?: any, apps?: Spintr.ISystemApp[]): IVisageSidebarMenuItem[] {
    let result: IVisageSidebarMenuItem[] = [{
        key: "home",
        name: localize("Hem"),
        icon: "home-1",
        activeIcon: "home",
        route: "/",
        routes: ["/"],
        activeMode: VisageSidebarMenuItemActiveMode.exact
    }];

    for (let item of items) {
        result.push(formatMenuItem(item));
    }

    if (!result.find(mi => mi.moduleKey === "people") && apps.find(a => a.id == SpintrTypes.SpintrApp.Coworkers && a.enabled)) {
        result.push({
            key: "people",
            name: localize("Medarbetare"),
            routes: ["/people"],
            route: "/people",
            moduleKey: "people",
            icon: getModuleKeyIcon("people"),
            activeIcon: getModuleKeyActiveIcon("people"),
            activeMode: VisageSidebarMenuItemActiveMode.relative
        });
    }

    if (!result.find(mi => mi.moduleKey === "project") && currentUser.rights.hasAccessToGroups) {
        result.push({
            key: "project",
            name: localize("Grupper"),
            routes: ["/groups"],
            route: "/groups",
            moduleKey: "project",
            icon: getModuleKeyIcon("project"),
            activeIcon: getModuleKeyActiveIcon("project"),
            activeMode: VisageSidebarMenuItemActiveMode.relative
        });
    }

    for (let item of result) {
        if (instantChildrenModuleKeys.indexOf(item.moduleKey) > -1) {
            item.children = instantLoadChildren(item.moduleKey, currentUser, instance);
        } else if (lazyLoadModuleKeys.indexOf(item.moduleKey) > -1) {
            item.lazyLoadChildren = true;
        }
    }

    return result;
}

const instantChildrenModuleKeys = ["suppliers", "people"];

const instantLoadChildren = (moduleKey: string, currentUser: any, instance?: any) => {
    if (instantChildrenModuleKeys.indexOf(moduleKey) === -1) {
        return undefined;
    }

    const isAdmin = currentUser.isAdmin;
    const isEditor = currentUser.isEditor;

    switch (moduleKey) {
        case "people":
            let items: IVisageSidebarMenuItem[] = [];

            items.push({
                key: "all-departments",
                name: localize("Alla") + " " + localize("Medarbetare").toLocaleLowerCase(),
                icon: "",
                route: "/people",
                routes: ["/people"],
                search: ""
            });

            items.push({
                key: "my-department",
                name: capitalizeFirstLetter(localize("Ubertype2_0_2")),
                icon: "",
                route: "/people",
                routes: ["/people"],
                search: "?d=" + currentUser.department.id
            });

            items.push({
                key: "my-office",
                name: capitalizeFirstLetter(localize("Ubertype8_0_2")),
                icon: "",
                route: "/people",
                routes: ["/people"],
                search: "?d=" + currentUser.department.office.id
            });

            if (!!instance && instance.get("enableCompanyLevel")) {
                items.push({
                    key: "my-company",
                    name: currentUser.department.office.company.name,
                    icon: "",
                    route: "/people",
                    routes: ["/people"],
                    search: "?d=" + currentUser.department.office.company.id
                });
            }

            if (currentUser.isAdmin || currentUser.isEditor) {
                items.push({
                    key: "administrators",
                    name: localize("Administratorer"),
                    icon: "",
                    route: "/people",
                    routes: ["/people"],
                    search: "?r=-5"
                });

                items.push({
                    key: "editors",
                    name: localize("Redaktorer"),
                    icon: "",
                    route: "/people",
                    routes: ["/people"],
                    search: "?r=-10"
                });

                items.push({
                    key: "external-users",
                    name: localize("EXTERNAL_USERS"),
                    icon: "",
                    route: "/people",
                    routes: ["/people"],
                    search: "?d=-5"
                });
            }

            return items;

            break;
        case "suppliers":
            return [
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "contact-persons",
                            name: localize("Kontaktpersoner"),
                            route: "/suppliers/contactpersons",
                            routes: ["/suppliers/contactpersons"],
                        },
                    ]
                    : []),
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "supplier-files",
                            name: localize("SUPPLIER_FILES"),
                            route: "/suppliers/files",
                            routes: ["/suppliers/files"],
                        },
                    ]
                    : []),
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "messages",
                            name: localize("Meddelanden"),
                            route: "/suppliers/messages",
                            routes: ["/suppliers/messages"],
                        },
                    ]
                    : []),
                {
                    key: "suppliers-admin",
                    name: localize("Administration"),
                    children: [
                        ...(isAdmin || isEditor
                            ? [
                                {
                                    key: "suppliers-groups",
                                    name: localize("SUPPLIER_GROUPS"),
                                    route: "/suppliers/groups",
                                    routes: ["/suppliers/groups"],
                                },
                            ]
                            : []),
                        ...(isAdmin || isEditor
                            ? [
                                {
                                    key: "suppliers-categories",
                                    name: localize("Kategorier"),
                                    route: "/suppliers/categories",
                                    routes: ["/suppliers/categories"],
                                },
                            ]
                            : []),
                        {
                            key: "suppliers-product-codes",
                            name: localize("Produktkoder"),
                            route: "/suppliers/product-codes",
                            routes: ["/suppliers/product-codes"],
                        },
                        ...(isAdmin || isEditor
                            ? [
                                {
                                    key: "suppliers-filecategories",
                                    name: localize("Filkategorier"),
                                    route: "/suppliers/filecategories",
                                    routes: ["/suppliers/filecategories"],
                                },
                            ]
                            : []),
                        ...(isAdmin || isEditor
                            ? [
                                {
                                    key: "suppliers-roles",
                                    name: localize("Roller"),
                                    route: "/suppliers/roles",
                                    routes: ["/suppliers/roles"],
                                },
                            ]
                            : []),
                    ],
                },
            ];

            break;
        default:
            break;
    }

    return undefined;
}

const lazyLoadModuleKeys = ["blogtop", "news", "files"];

export const getSubMenu = (moduleKey: string, dispatch: any, currentUser: any, instance: any) => {
    return new Promise((resolve, reject) => {
        switch (moduleKey) {
            case "blogtop":
                let blogRequests = [
                    api.get("/api/v1/blogs/gettags"),
                    api.get("/api/v1/blogs/userblogs"),
                    api.get("/api/v1/blogs/0/archive")
                ];

                axios.all(blogRequests).then((response) => {
                    const tagsResponse = response[0];
                    const userBlogsResponse = response[1];
                    const archiveResponse = response[2];

                    let items: IVisageSidebarMenuItem[] = [];

                    items.push({
                        key: "popular-blogs",
                        name: localize("Popularaste"),
                        icon: "",
                        route: "/blogs/popular",
                        routes: ["/blogs/popular"],
                        search: ""
                    });

                    if (!!tagsResponse &&
                        !!tagsResponse.data &&
                        tagsResponse.data.length > 0) {
                        items.push({
                            key: "blog-subjects",
                            name: localize("Amnen"),
                            icon: "",
                            search: "",
                            children: tagsResponse.data.map((t: any) => {
                                return {
                                    key: "blog-subject-" + t.id,
                                    name: t.text,
                                    icon: "",
                                    route: "/blogs/tags/" + t.text,
                                    routes: ["/blogs/tags/" + t.text],
                                    search: "",
                                }
                            })
                        });
                    }

                    if (!!archiveResponse &&
                        !!archiveResponse.data &&
                        archiveResponse.data.length > 0) {
                        items.push({
                            key: "blog-history",
                            name: localize("Historik"),
                            icon: "",
                            search: "",
                            children: archiveResponse.data.map((h: any) => {
                                return {
                                    key: "blog-history-" + h,
                                    name: h,
                                    icon: "",
                                    route: "/blogs/history/" + h,
                                    routes: ["/blogs/history/" + h],
                                    search: "",
                                }
                            })
                        });
                    }

                    if (!!userBlogsResponse &&
                        !!userBlogsResponse.data &&
                        userBlogsResponse.data.length > 0) {
                        items.push({
                            key: "user-blogs",
                            name: localize("MinaBloggar"),
                            route: "/blogs/my",
                            routes: ["/blogs/my"],
                            icon: "",
                            search: "",
                            children: userBlogsResponse.data.map((u: any) => {
                                let blogSlug = u.url.split("/")[2];

                                return {
                                    key: "user-blogs-" + u.id,
                                    name: u.name,
                                    icon: "",
                                    route: "/blogs/" + blogSlug,
                                    routes: ["/blogs/" + blogSlug],
                                    search: "",
                                    children: [{
                                        key: "user-blogs-" + u.id + "-archive",
                                        name: localize("Arkiv"),
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/archive",
                                        routes: ["/blogs/" + blogSlug + "/archive"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-published",
                                        name: localize("Publicerade") + " (" + u.articleCount.published + ")",
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/published",
                                        routes: ["/blogs/" + blogSlug + "/published"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-drafts",
                                        name: localize("Utkast") + " (" + u.articleCount.drafts + ")",
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/drafts",
                                        routes: ["/blogs/" + blogSlug + "/drafts"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-planned",
                                        name: localize("Planerade") + " (" + u.articleCount.scheduled + ")",
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/planned",
                                        routes: ["/blogs/" + blogSlug + "/planned"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-expired",
                                        name: localize("Forfallet") + " (" + u.articleCount.expired + ")",
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/expired",
                                        routes: ["/blogs/" + blogSlug + "/expired"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-deleted",
                                        name: localize("Borttagna") + " (" + u.articleCount.deleted + ")",
                                        icon: "",
                                        route: "/blogs/" + blogSlug + "/deleted",
                                        routes: ["/blogs/" + blogSlug + "/deleted"],
                                        search: ""
                                    }, {
                                        key: "user-blogs-" + u.id + "-create",
                                        name: localize("NyBloggpost"),
                                        icon: "add",
                                        route: "/blogs/" + blogSlug + "/create",
                                        routes: ["/blogs/" + blogSlug + "/create"],
                                        search: ""
                                    }]
                                }
                            })
                        });
                    }

                    items.push({
                        key: "all-blogs",
                        name: localize("AllaBloggar"),
                        icon: "",
                        route: "/blogs/list",
                        routes: ["/blogs/list"],
                        search: ""
                    });

                    items.push({
                        key: "create-blog",
                        name: localize("Nyblogg"),
                        route: "/blogs/create",
                        routes: ["/blogs/create"],
                        icon: "add",
                        search: ""
                    });

                    items = [{
                        key: "blogs",
                        name: localize("Bloggar"),
                        icon: "card-edit",
                        iconStyle: "Custom",
                        activeIcon: "card-edit",
                        // search: "",
                        children: items,
                        isExpanded: true,
                        isLoading: false,
                        moduleKey: "blogtop",
                        // route: "/blogs",
                        // routes: ["/blogs"],
                        // activeMode: VisageSidebarMenuItemActiveMode.relative
                    }];

                    resolve(items);
                });

                break;
            case "news":
                let newsRequests = [
                    api.get('/api/v1/newsqueries'),
                    api.get('/api/v1/news/getmynewscount'),
                ];

                if (currentUser.isAdmin ||
                    currentUser.isEditor) {
                    newsRequests.push(api.get('/api/v1/news/getadminnewscount'));
                }

                axios.all(newsRequests).then((response) => {
                    const newsQueriesResponse = response[0];
                    const myNewsCountResult = response[1];

                    let items: IVisageSidebarMenuItem[] = [];

                    items.push({
                        key: "top-news",
                        name: localize("Toppnyheter"),
                        icon: "",
                        route: "/news/top",
                        routes: ["/news/top"],
                        search: ""
                    });

                    if (!!newsQueriesResponse &&
                        !!newsQueriesResponse.data &&
                        newsQueriesResponse.data.length > 0) {
                        const queries = newsQueriesResponse.data.filter(q => !!q.tags && q.tags.length > 0);

                        items.push({
                            key: "saved-news-queries",
                            name: localize("SparadeSokningar"),
                            icon: "",
                            search: "",
                            children: queries.map((q: any) => {
                                return {
                                    key: "saved-news-query" + q.id,
                                    name: q.name,
                                    icon: "",
                                    route: "/news",
                                    routes: ["/news"],
                                    search: !!q.tags && q.tags.length > 0 ? ("?tagId=" + q.tags[0].id) : undefined
                                }
                            })
                        });
                    }

                    if (!!myNewsCountResult &&
                        !!myNewsCountResult.data &&
                        (myNewsCountResult.data.draftCount > 0 ||
                            myNewsCountResult.data.publishedCount > 0 ||
                            myNewsCountResult.data.oldCount > 0 ||
                            myNewsCountResult.data.deletedCount > 0)) {
                        let myNews = {
                            key: "my-news",
                            name: localize("MinaNyheter"),
                            icon: "",
                            search: "",
                            children: []
                        };

                        if (myNewsCountResult.data.draftCount > 0) {
                            myNews.children.push({
                                key: "my-news-" + 0,
                                name: localize("Utkast") + " (" + myNewsCountResult.data.draftCount + ")",
                                icon: "",
                                route: "/news/my/drafts",
                                routes: ["/news/my/drafts"],
                            })
                        }

                        if (myNewsCountResult.data.publishedCount > 0) {
                            myNews.children.push({
                                key: "my-news-" + 1,
                                name: localize("Publicerade") + " (" + myNewsCountResult.data.publishedCount + ")",
                                icon: "",
                                route: "/news/my/published",
                                routes: ["/news/my/published"],
                            })
                        }

                        if (myNewsCountResult.data.oldCount > 0) {
                            myNews.children.push({
                                key: "my-news-" + 2,
                                name: localize("Utgatt") + " (" + myNewsCountResult.data.oldCount + ")",
                                icon: "",
                                route: "/news/my/old",
                                routes: ["/news/my/old"],
                            })
                        }

                        if (myNewsCountResult.data.deletedCount > 0) {
                            myNews.children.push({
                                key: "my-news-" + 3,
                                name: localize("Borttagna") + " (" + myNewsCountResult.data.deletedCount + ")",
                                icon: "",
                                route: "/news/my/deleted",
                                routes: ["/news/my/deleted"],
                            })
                        }

                        items.push(myNews);
                    }
                    if (currentUser.isAdmin ||
                        currentUser.isEditor) {
                        const adminNewsCountResult = response[2];

                        if (!!adminNewsCountResult &&
                            !!adminNewsCountResult.data &&
                            (adminNewsCountResult.data.draftCount > 0 ||
                                adminNewsCountResult.data.publishedCount > 0 ||
                                adminNewsCountResult.data.oldCount > 0 ||
                                adminNewsCountResult.data.deletedCount > 0)) {
                            let adminNews = {
                                key: "admin-news",
                                name: localize("Redaktor"),
                                icon: "",
                                search: "",
                                children: []
                            };

                            if (adminNewsCountResult.data.draftCount > 0) {
                                adminNews.children.push({
                                    key: "admin-news-" + 0,
                                    name: localize("Utkast") + " (" + adminNewsCountResult.data.draftCount + ")",
                                    icon: "",
                                    route: "/news/admin/drafts",
                                    routes: ["/news/admin/drafts"],
                                })
                            }

                            if (adminNewsCountResult.data.publishedCount > 0) {
                                adminNews.children.push({
                                    key: "admin-news-" + 1,
                                    name: localize("Publicerade") + " (" + adminNewsCountResult.data.publishedCount + ")",
                                    icon: "",
                                    route: "/news/admin/published",
                                    routes: ["/news/admin/published"],
                                })
                            }

                            if (adminNewsCountResult.data.oldCount > 0) {
                                adminNews.children.push({
                                    key: "admin-news-" + 2,
                                    name: localize("Utgatt") + " (" + adminNewsCountResult.data.oldCount + ")",
                                    icon: "",
                                    route: "/news/admin/old",
                                    routes: ["/news/admin/old"],
                                })
                            }

                            if (adminNewsCountResult.data.deletedCount > 0) {
                                adminNews.children.push({
                                    key: "admin-news-" + 3,
                                    name: localize("Borttagna") + " (" + adminNewsCountResult.data.deletedCount + ")",
                                    icon: "",
                                    route: "/news/admin/deleted",
                                    routes: ["/news/admin/deleted"],
                                })
                            }

                            items.push(adminNews);
                        }
                    }

                    items = [{
                        key: "news",
                        name: localize("Nyheter"),
                        icon: "News",
                        iconStyle: "Custom",
                        activeIcon: "Discovery",
                        // search: "",
                        children: items,
                        isExpanded: true,
                        isLoading: false,
                        moduleKey: "news",
                        // route: "/news",
                        // routes: ["/news"],
                        // activeMode: VisageSidebarMenuItemActiveMode.relative
                    }];

                    resolve(items);
                });

                break;
            case "people":
                let requests = [
                    api.get('/api/units?take=1000&onlyPopulated=true'),
                    api.get('/api/users/roles?take=1000&onlyPopulated=true')
                ];

                axios.all(requests).then((response) => {
                    const unitsResponse = response[0];
                    const rolesResponse = response[1];

                    let items: IVisageSidebarMenuItem[] = [];

                    items.push({
                        key: "all-departments",
                        name: localize("Alla") + " " + localize("Medarbetare").toLocaleLowerCase(),
                        icon: "",
                        route: "/people",
                        routes: ["/people"],
                        search: ""
                    });

                    items.push({
                        key: "my-department",
                        name: capitalizeFirstLetter(localize("Ubertype2_0_2")),
                        icon: "",
                        route: "/people",
                        routes: ["/people"],
                        search: "?d=" + currentUser.department.id
                    });

                    items.push({
                        key: "my-office",
                        name: capitalizeFirstLetter(localize("Ubertype8_0_2")),
                        icon: "",
                        route: "/people",
                        routes: ["/people"],
                        search: "?d=" + currentUser.department.office.id
                    });

                    if (instance.get("enableCompanyLevel")) {
                        items.push({
                            key: "my-company",
                            name: currentUser.department.office.company.name,
                            icon: "",
                            route: "/people",
                            routes: ["/people"],
                            search: "?d=" + currentUser.department.office.company.id
                        });
                    }

                    if (currentUser.isAdmin || currentUser.isEditor) {
                        items.push({
                            key: "administrators",
                            name: localize("Administratorer"),
                            icon: "",
                            route: "/people",
                            routes: ["/people"],
                            search: "?r=-5"
                        });

                        items.push({
                            key: "editors",
                            name: localize("Redaktorer"),
                            icon: "",
                            route: "/people",
                            routes: ["/people"],
                            search: "?r=-10"
                        });

                        items.push({
                            key: "external-users",
                            name: localize("EXTERNAL_USERS"),
                            icon: "",
                            route: "/people",
                            routes: ["/people"],
                            search: "?d=-5"
                        });
                    }

                    // if (unitsResponse &&
                    //     unitsResponse.data &&
                    //     unitsResponse.data.offices &&
                    //     unitsResponse.data.offices.length > 0) {
                    //     items.push({
                    //         key: "departments",
                    //         name: localize("Avdelning"),
                    //         icon: "",
                    //         search: "",
                    //         children: unitsResponse.data.offices.map((o: any) => {
                    //             return {
                    //                 key: "office" + o.id,
                    //                 name: o.name,
                    //                 icon: "",
                    //                 route: "/people",
                    //                 routes: ["/people"],
                    //                 search: "?d=" + o.id,
                    //                 children: !o.departments ? undefined : o.departments.map((d: any) => {
                    //                     return {
                    //                         key: "department" + d.id,
                    //                         name: d.name,
                    //                         icon: "",
                    //                         route: "/people",
                    //                         routes: ["/people"],
                    //                         search: "?d=" + d.id,
                    //                     }
                    //                 })
                    //             }
                    //         })
                    //     })
                    // }

                    // if (rolesResponse &&
                    //     rolesResponse.data &&
                    //     rolesResponse.data.length > 0) {
                    //     items.push({
                    //         key: "roles",
                    //         name: localize("Roller"),
                    //         icon: "",
                    //         search: "",
                    //         children: rolesResponse.data.map((r: any) => {
                    //             return {
                    //                 key: "role" + r.id,
                    //                 name: r.name,
                    //                 icon: "",
                    //                 route: "/people",
                    //                 routes: ["/people"],
                    //                 search: "?r=" + r.id,
                    //             }
                    //         })
                    //     })
                    // }

                    items = [{
                        key: "coworkers",
                        name: localize("Medarbetare"),
                        icon: "personalcard",
                        activeIcon: "VisageSidebarPeopleFilled",
                        search: "",
                        children: items,
                        isExpanded: true,
                        isLoading: false,
                        moduleKey: "people",
                        // route: "/people",
                        // routes: ["/people"],
                        // activeMode: VisageSidebarMenuItemActiveMode.relative
                    }];

                    resolve(items);
                }).catch(() => {
                    reject();
                });

                break;
            case "files":
                api.get("/api/folders/rootfolders").then((response: any) => {
                    let items: IVisageSidebarMenuItem[] = [{
                        key: "files",
                        name: localize("Filer"),
                        icon: "Folder",
                        activeIcon: "Folder",
                        children: [],
                        isExpanded: true,
                        isLoading: false,
                        moduleKey: "files",
                    }];

                    const officeSources = response.data.filter((s: any) =>
                        s.source > SpintrTypes.FolderRootSource.SpintrGroup &&
                        s.source !== SpintrTypes.FolderRootSource.Office365Group
                    );

                    const localSources = response.data.filter((s: any) =>
                        s.source === SpintrTypes.FolderRootSource.SpintrPublic
                    );

                    const groupSources = response.data.filter((s: any) =>
                        s.source === SpintrTypes.FolderRootSource.SpintrGroup ||
                        s.source === SpintrTypes.FolderRootSource.Office365Group
                    );

                    if (!!officeSources && officeSources.length > 0) {
                        items[0].children.push({
                            key: "office-category",
                            name: localize("Office365"),
                            children: officeSources.map((item: any) => {
                                return {
                                    key: "office-source-" + item.id,
                                    name: item.name,
                                    route: "/files/" + item.source + "/d" + item.id,
                                    routes: ["/files/" + item.source],
                                    activeMode: VisageSidebarMenuItemActiveMode.relative
                                }
                            })
                        })
                    }

                    if (!!localSources && localSources.length > 0) {
                        items[0].children.push({
                            key: "local-files",
                            name: instance.get("name"),
                            route: "/files/" + localSources[0].source + "/d" + localSources[0].id,
                            routes: ["/files/" + localSources[0].source],
                            activeMode: VisageSidebarMenuItemActiveMode.relative
                        });
                    }

                    if (!!groupSources && groupSources.length > 0) {
                        items[0].children.push({
                            key: "groups-category",
                            name: localize("Grupper"),
                            children: groupSources.map((item: any) => {
                                let route = "/files/" + item.source + "/d" + item.id;

                                return {
                                    key: "groups-source-" + item.id,
                                    name: item.name,
                                    route,
                                    routes: [route],
                                    activeMode: VisageSidebarMenuItemActiveMode.relative
                                }
                            })
                        })
                    }

                    resolve(items);
                }).catch(() => { });
                break;
            default:
                break;
        }
    });
}
