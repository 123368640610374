import { Pivot, PivotItem, Separator } from "@fluentui/react";
import classnames from "classnames";
import moment from "moment";
import {
    DayOfWeek,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Label as FabricLabel,
    Modal,
    PrimaryButton,
} from "@fluentui/react";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { IStackStyles, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { localize } from "src/l10n";
import { getLanguageOptions } from "src/l10n/localize";
import { ReactComponent as Template1 } from "src/pages/icons/template-1.svg";
import { ReactComponent as Template2 } from "src/pages/icons/template-2.svg";
import { ReactComponent as Template3 } from "src/pages/icons/template-3.svg";
import { ReactComponent as Template4 } from "src/pages/icons/template-4.svg";
import { setConfirmPopup } from "src/popups/actions";
import { canUserDeletePage } from "src/privileges/utils";
import { ContentVersionsList } from "src/spintr/components";
import FilePicker from "src/spintr/components/FilePicker";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import {
    Autosaver,
    Breadcrumbs,
    ContentImageViewerAndSelector,
    ContentWithInfoPanel,
    EditableContentSections,
    Label,
    Loader,
    PageHeader,
    PageInfoPanel,
    setAdminNoPadding,
    setDisableWrapperBackground,
    WebStructure,
} from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import UberContentView from "src/ui/components/UberContent/UberContentView";
import { Style } from "src/ui/helpers";
import { autosave, debounce, isAnythingDirty, NoOp, scrollToTop, validateRequiredTextField } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import toWebSafe from "src/utils/toWebSafe";
import { MandatoryText } from "../../ui/components/Forms";
import "./PageFormView.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import {
    clearAttachedFilesAndFolders,
    fetchAttachedFile,
    fetchAttachedFolder,
    removeAttachedFile,
    removeAttachedFolder,
} from "../page-actions";
import { fetchMenu } from "src/sidebar/utils";
import SplitForm from "src/ui/components/SplitForm/SplitForm";
import Visage2FullScreenHeader from "src/ui/components/Visage2FullScreenView/Visage2FullScreenHeader";
import { getVideosToBeUploaded } from "src/ui/components/UberContent";

const stackStyles: IStackStyles = {
    root: {
        paddingTop: 0,
    },
};

interface IPageRouteParams {
    versionId: string;
    id: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    instance: any;
    history: any;
    isAdmin: any;
    isEditor: any;
    dispatch: (action: Action) => void;
    currentUser: any;
    enableExternalFiles: boolean;
    privileges: any;
    requiredTagCount: number;
    enableCanea: boolean;
    group?: any;
    apps?: any;
    isSmallViewMode?: boolean;
    attachedFiles: any;
    attachedFolders: any;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    disableCommentsByDefault?: boolean;
}

interface IState {
    isLoading: boolean;

    autosaveId: number;
    isAutosaving: boolean;
    lastAutosave?: Date;

    page?: any;
    hideDialog: boolean;
    dialogMessage?: string;
    showRejectModal: boolean;
    rejectMessage: string;
    isForGroupId?: number;
    saveError?: string[];
    customTemplates?: any[];
    displayApplyTemplateDialog?: boolean;
    templateIdToApply?: number;
    isTemplate: boolean;
    displayDeleteDialog?: boolean;
    isFilePickerOpen: boolean;
    saveSuccess?: boolean;
    saveSuccessMessage?: string;
    breadcrumbs: any[];
    parentPageMenuId: number;
    selectedPivotKey: string;
    enableFieldValidation?: boolean;
    isSaving?: boolean;
    closeDialogFn?: any;
    isDirty?: boolean;
    displayPreview?: boolean;
}

class PageFormView extends Component<IProps, IState> {
    public static contextType = ThemeContext;
    private contentReview = this.props.match.params.versionId !== undefined;
    private isNew = window.location.href.indexOf("pages/create") > -1;
    private referenceModel: any;

    constructor(props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const groupId = query.get("groupId");
        const isTemplate = query.get("isTemplate");
        const parentPageMenuId = query.get("parent");

        this.state = {
            isLoading: true,

            autosaveId: 0,
            isAutosaving: false,

            hideDialog: true,
            showRejectModal: false,
            rejectMessage: "",
            isForGroupId: groupId ? parseInt(groupId) : null,
            isTemplate: isTemplate === "true",
            isFilePickerOpen: false,
            breadcrumbs: [],
            parentPageMenuId: parentPageMenuId ? parseInt(parentPageMenuId) : null,
            selectedPivotKey: window.location.href.indexOf("pages/create") > -1 ? "content" : "content",
        };
        this.referenceModel = this.state.page;

        this.onAutosave = this.onAutosave.bind(this);
        props.dispatch(clearAttachedFilesAndFolders());
    }

    private _showDialog = (message, fn = undefined): void => {
        this.setState({
            hideDialog: false,
            dialogMessage: message,
            closeDialogFn: fn,
        });
    };

    private _closeDialog = (): void => {
        if (this.state.isSaving) {
            this.setState({ isLoading: true });
            if (this.state.closeDialogFn) {
                this.state.closeDialogFn();
            }
        }
        this.setState({ hideDialog: true }, () => {
            if (!!this.contentReview) {
                const { id } = this.state.page;

                if (id && id !== 0) {
                    this.props.history.push(`/pages/${id}/edit`);
                }
            }
        });
    };

    async initPage(page, doAfter?) {
        if (!page.uberContent) {
            page.uberContent = {
                uberContentRows: [],
            };

            if (!!page.preamble && page.preamble.length > 0) {
                page.uberContent.uberContentRows.push({
                    id: 0,
                    index: page.uberContent.uberContentRows.length,
                    uberContentColumns: [
                        {
                            id: 0,
                            state: 0,
                            type: 0,
                            index: 0,
                            content: '<div style="font-weight: 600;">' + page.preamble + "</div>",
                        },
                    ],
                });
            }

            if (!!page.content && page.content.length > 0) {
                page.uberContent.uberContentRows.push({
                    id: 0,
                    index: page.uberContent.uberContentRows.length,
                    uberContentColumns: [
                        {
                            id: 0,
                            state: 0,
                            type: 0,
                            index: 0,
                            content: page.content,
                        },
                    ],
                });
            }
        } else {
            page.uberContent.uberContentRows.map((ucr) => {
                ucr.uberContentColumns.map((ucc) => {
                    if (ucc.type === SpintrTypes.UberContentSectionType.IFrame && ucc.content) {
                        const tempElement = document.createElement("div");
                        tempElement.innerHTML = ucc.content;
                        const attributes = tempElement.firstElementChild.attributes;
                        const srcItem = attributes.getNamedItem("src");
                        const heightItem = attributes.getNamedItem("height");

                        if (srcItem) {
                            ucc.iframeUrl = srcItem.value;
                        }

                        if (heightItem) {
                            ucc.iframeHeight = heightItem.value;
                        }

                        return ucc;
                    }

                    return ucc;
                });
                return ucr;
            });
        }

        page.publishDate = new Date(page.publishDate);

        if (page.publishEndDate) {
            page.publishEndDate = new Date(page.publishEndDate);
        }

        if (page.expireDate) {
            page.expireDate = new Date(page.expireDate);
        }

        if (page.menuCategory && page.menuCategory.indexOf("group_") >= 0) {
            page.groupId = parseInt(page.menuCategory.substring(6));
        } else if (page.id === 0 && !!this.state.isForGroupId) {
            page.groupId = this.state.isForGroupId;
        }

        if (page.menuUrl && page.menuUrl.length > 0 && page.menuUrl.indexOf("/") > -1) {
            page.menuUrl = page.menuUrl.split("/").pop();
        }

        page.hasCustomUrl = page.menuUrl?.length > 0 && page.menuUrl !== toWebSafe(page.title);

        if (!!page.menuTitle && page.menuTitle !== page.title && page.menuTitle !== "") {
            page.hasCustomMenuTitle = true;
        }

        page.tagStrings = page.tags.map((tag) => {
            if (typeof tag === "string") {
                return tag;
            } else {
                return tag.text;
            }
        });

        // page.attachedFilesAndFolders = [
        //     ...(page.attachedFiles ? page.attachedFiles : []),
        //     ...(page.attachedFolders ? page.attachedFolders : []),
        // ];

        for (const af of page.attachedFiles) {
            this.props.dispatch(fetchAttachedFile(af));
        }
        for (const af of page.attachedFolders) {
            this.props.dispatch(fetchAttachedFolder(af, undefined));
        }

        const breadcrumbs = await this.fetchBreadcrumbs(page.menuId);

        this.setState(
            {
                isLoading: false,
                page,
                isForGroupId: page.groupId ? page.groupId : this.state.isForGroupId,
                breadcrumbs,
            },
            () => {
                this.referenceModel = this.state.page;
                if (doAfter) {
                    doAfter();
                }
            }
        );
    }

    private fetchBreadcrumbs = async (id: number) => {
        let breadcrumbs = [];
        try {
            const response = await api.get("/api/v1/menu/breadcrumbs", { params: { id } });

            breadcrumbs = response.data.map((menuItem) => ({
                link: "/" + menuItem.url,
                text: menuItem.title,
            }));
        } catch {
            NoOp();
        }

        return breadcrumbs;
    };

    catchError(error) {
        let saveError = ["TeknisktFel"];

        if (error && error.response && error.response.data && error.response.data.errorlist) {
            saveError = error.response.data.errorlist;
        }

        this.setState(
            {
                saveError,
                isLoading: false,
            },
            () => {
                scrollToTop();
            }
        );
    }

    fetchCustomTemplates() {
        api.get("/api/pages/templates")
            .then((response) => {
                this.setState({
                    customTemplates: response.data,
                });
            })
            .catch(this.catchError.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search != prevProps.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            const isTemplate = query.get("isTemplate") === "true";

            this.setState(
                {
                    isTemplate,
                    customTemplates: [],
                    isLoading: true,
                },
                this.initialize
            );
            return;
        }

        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState(
                {
                    isLoading: true,
                },
                this.initialize
            );
        }

        if (this.state.page && this.state.page.id === 0 && prevState.page) {
            const prevRows = prevState.page.uberContent.uberContentRows.length;
            const newRows = this.state.page.uberContent.uberContentRows.length;

            if (prevRows === 0 && prevRows !== newRows) {
                this.referenceModel = this.state.page;
            }
        }
    }

    componentDidMount() {
        this.initialize();
        this.props.dispatch(setDisableWrapperBackground(true));
        this.props.dispatch(setAdminNoPadding(true));
    }

    componentWillUnmount() {
        this.props.dispatch(setDisableWrapperBackground(false));
        this.props.dispatch(setAdminNoPadding(false));
    }

    initialize = () => {
        if (!this.state.isTemplate) {
            this.fetchCustomTemplates();
        }

        let isNew, pageId;

        if (window.location.href.indexOf("pages/create") > -1) {
            isNew = true;
        } else {
            isNew = false;
            pageId = this.props.match.params.id;
        }

        if (isNew) {
            this.initPage({
                id: 0,
                menuParent: !!this.state.parentPageMenuId
                    ? this.state.parentPageMenuId
                    : !!this.state.isForGroupId
                    ? 0
                    : -1,
                menuPosition: this.state.parentPageMenuId ? 0 : -1,
                menuId: 0,
                template: !!this.state.isForGroupId ? 2 : 1,
                title: "",
                body: "",
                preamble: "",
                publishDate: new Date(),
                allowComments: !this.props.disableCommentsByDefault,
                isPublished: true,
                menuItemVisible: true,
                menuItemActive: true,
                isAllowed: true,
                isPublisher: true,
                lastChanged: new Date(),
                menuUrl: "",
                tags: [],
                publishers: [
                    {
                        id: this.props.currentUser.id,
                        name: this.props.currentUser.name,
                        imageUrl: this.props.currentUser.images["250x999"],
                        info: this.props.currentUser.department.name,
                    },
                ],
                factcheckers: [],
                approvers: [],
                followers: [],
                targets: [],
                hiddentargets: [],
                modules: {
                    msystem: {
                        allowDeviations: false,
                    },
                    improvements: {
                        allowImprovements: false,
                    },
                },
                attachedFiles: [],
                attachedFolders: [],
                attachedSharePointListSearches: [],
                links: [],
            });
        } else {
            let fetchPage = () => {
                api.get("/api/v1/pages/" + pageId, {
                    params: {
                        editMode: true,
                    },
                })
                    .then((response) => {
                        this.initPage(response.data);
                    })
                    .catch(this.catchError.bind(this));
            };
            if (this.contentReview) {
                fetchPage = () => {
                    api.get("/api/v1/pages/" + pageId, {
                        params: {
                            versionId: this.props.match.params.versionId,
                            editMode: true,
                        },
                    })
                        .then((response) => {
                            this.initPage(response.data);
                        })
                        .catch(this.catchError.bind(this));
                };
            }

            fetchPage();
        }
    };

    handleTitleChange = (title?: string) => {
        let menuTitle = this.state.page.title === this.state.page.menuTitle ? title : this.state.page.menuTitle;

        let hasCustomMenuTitle = this.state.page.title !== this.state.page.menuTitle && !this.isNew;

        this.setState({
            page: {
                ...this.state.page,
                title,
                menuTitle,
                hasCustomMenuTitle,
            },
        });
    };

    private handleCancelClick = () => {
        this.props.history.goBack();
    };

    private handleSaveClick = () => {
        this.setState(
            {
                isDirty: false,
                enableFieldValidation: true,
            },
            () => {
                this.save(false);
            }
        );
    };

    private handleSaveDraftClick = () => {
        if (!this.state.isTemplate) {
            this.save(true);
        }
    };

    private onChangeCheckbox = (ev, checked) => {
        ev.persist();

        this.setState((prevState) => ({
            page: {
                ...prevState.page,
                [ev.target.name]: checked,
            },
        }));
    };

    renderTemplateSvg(templateId, customTemplate?: any) {
        const allowTemplate4 = !!this.props.apps.items.find(
            (a) => a.id === SpintrTypes.SpintrApp.DeviationManager && a.enabled
        );

        if (!allowTemplate4 && templateId === 4) {
            return null;
        }

        const isActive =
            (!this.state.page.selectedCustomTemplate && !customTemplate && this.state.page.template === templateId) ||
            (!!this.state.page.selectedCustomTemplate &&
                !!customTemplate &&
                this.state.page.selectedCustomTemplate === customTemplate.id);

        return (
            <div
                className={"templateSvgWrapper no-user-select" + (isActive ? " active" : "")}
                onDoubleClick={() => {
                    this.setState({
                        selectedPivotKey: "content",
                    });
                }}
                onClick={() => {
                    if (!!customTemplate) {
                        this.setState({
                            displayApplyTemplateDialog: true,
                            templateIdToApply: customTemplate.id,
                        });

                        return;
                    }

                    if (this.state.page.template === templateId) {
                        return;
                    }

                    if (templateId === 4) {
                        let allCurrentText = "";

                        for (let r of this.state.page.uberContent.uberContentRows) {
                            for (let c of r.uberContentColumns) {
                                allCurrentText += c.content;
                            }
                        }

                        this.setState({
                            page: {
                                ...this.state.page,
                                template: templateId,
                                content: allCurrentText,
                                uberContent: {
                                    ...this.state.page.uberContent,
                                    uberContentRows: [],
                                },
                                selectedCustomTemplate: null,
                            },
                        });
                    } else {
                        this.setState({
                            page: {
                                ...this.state.page,
                                template: templateId,
                                selectedCustomTemplate: null,
                            },
                        });
                    }
                }}
            >
                {templateId === 1 ? (
                    <Template1 className="templateSvg" />
                ) : templateId === 2 ? (
                    <Template2 className="templateSvg" />
                ) : templateId === 3 ? (
                    <Template3 className="templateSvg" />
                ) : templateId === 4 ? (
                    <Template4 className="templateSvg" />
                ) : null}
            </div>
        );
    }

    postPage = (page) => {
        const enablePrivilegesMatrix = this.props.instance.get("enablePrivilegesMatrix");
        const enableContentReview = this.props.instance.get("enableContentReview");
        const isGroupAdmin =
            this.props.group &&
            this.props.group.members &&
            !!this.props.group.members.find((m) => m.id === this.props.currentUser.id && m.isAdministrator);

        const videosToBeUploaded = getVideosToBeUploaded(page.uberContent);

        api.post("/api/v1/pages/" + page.id, page)
            .then(async (response) => {

                await Promise.all(videosToBeUploaded.map((video) => {
                    let body = new FormData();
                    body.append("ticket", video.ticket);
                    body.append("file", video.file);
                    body.append("uberId", response.data.id);
                    return api.post("/api/v1/azureupload", body);
                }));

                fetchMenu(this.props.dispatch, this.props.currentUser);
                this.initPage(response.data, () => {
                    if (page.isAutosave) {
                        this.setState({
                            page: {
                                ...this.state.page,
                                id: response.data.id,
                            },
                            isAutosaving: false,
                            lastAutosave: new Date(),
                        });
                        return;
                    }

                    if (
                        !(this.props.isAdmin || this.props.isEditor || isGroupAdmin) &&
                        enablePrivilegesMatrix &&
                        enableContentReview
                    ) {
                        this.setState({
                            isSaving: false,
                            saveSuccess: true,
                            saveSuccessMessage: localize("DinaAndringarHarSkickatsInForGranskning"),
                        });
                    } else {
                        const saveText = this.state.isTemplate
                            ? localize("Mall") + " " + localize("Sparad").toLowerCase()
                            : localize("TextsidaSparad");

                        this.setState({
                            isSaving: false,
                            saveSuccess: true,
                            saveSuccessMessage: saveText,
                        });

                        if (this.state.isTemplate) {
                            return;
                        }

                        let placed = page.menuParent !== -1;

                        if (placed) {
                            this.props.history.push(`/goto/${response.data.id}`);
                        } else {
                            this.props.history.push(`/admin/pages`);
                        }
                    }
                });
            })
            .catch(this.catchError.bind(this));
    };
    save(saveAsDraft = false, isAutosave = false) {
        let dat = this;

        this.setState(
            {
                isAutosaving: isAutosave ? true : this.state.isAutosaving,
                isLoading: isAutosave ? this.state.isLoading : true,
                isSaving: true,
            },
            () => {
                let page = {
                    ...this.state.page,
                    content: this.state.page.template !== 4 ? "" : this.state.page.content,
                    isNewDraft: saveAsDraft ? true : false,
                    isDraft: saveAsDraft ? true : false,
                };

                if (this.state.isForGroupId) {
                    page.groupId = this.state.isForGroupId;

                    if (typeof page.menuPosition === "undefined" || page.menuPosition < 0) {
                        page.menuPosition = 0;
                    }

                    if (typeof page.menuParent === "undefined" || page.menuParent < 0) {
                        page.menuParent = null;
                    }

                    if (typeof page.menuId === "undefined" || page.menuId === 0) {
                        page.menuId = null;
                    }

                    page.menuTitle = page.title;
                }

                if (this.state.isTemplate) {
                    page.menuItemActive = false;
                    page.menuItemVisible = false;
                    page.menuParent = 0;
                    page.menuPosition = 0;
                    page.menuUrl = "";
                    page.menuCategory = "";
                    page.isPublished = false;
                    page.isDraft = true;
                }

                page.uberContent.uberContentRows = page.uberContent.uberContentRows.filter(
                    (ucr) => ucr.uberContentColumns && ucr.uberContentColumns.length > 0
                );

                if (this.state.page.template !== 4) {
                    for (let ucr of page.uberContent.uberContentRows) {
                        ucr.index = page.uberContent.uberContentRows.indexOf(ucr);

                        for (let ucc of ucr.uberContentColumns) {
                            if (ucc.content !== "") {
                                // ignore empty uberContents to prevent saving without choosing a section

                                let contentPart = "";

                                if (ucc.type === SpintrTypes.UberContentSectionType.Image) {
                                    let content;
                                    if (ucc.content[0] === "{") {
                                        const contentObj = JSON.parse(ucc.content);

                                        content = contentObj.content;
                                    } else {
                                        content = ucc.content;
                                    }

                                    contentPart = '<img src="' + content + '" alt="" />';
                                 } else if (ucc.type === SpintrTypes.UberContentSectionType.Link) {
                                    if (ucc.content[0] === "{") {
                                        const contentObj = JSON.parse(ucc.content);

                                        contentPart = `<a href="${contentObj.url}">${contentObj.title}</a>`;
                                    }
                                } else if (ucc.type === SpintrTypes.UberContentSectionType.UserList) {
                                    if (ucc.content[0] === "{") {
                                        const contentObj = JSON.parse(ucc.content);

                                        contentPart = contentObj.title;
                                    }
                                } else if (ucc.type === SpintrTypes.UberContentSectionType.ExpandableField) {
                                    if (ucc.content[0] === "{") {
                                        const contentObj = JSON.parse(ucc.content);

                                        contentPart = `<p>${contentObj.title}</p> ${contentObj.text}`;
                                    }
                                } else if (ucc.type === SpintrTypes.UberContentSectionType.DataWidget ||
                                    ucc.type === SpintrTypes.UberContentSectionType.TeaserBox ||
                                    ucc.type === SpintrTypes.UberContentSectionType.File) {
                                    contentPart = "";
                                } else {
                                    contentPart = ucc.content;
                                }

                                page.content += contentPart + "<br />"; // saving uberContent as content for searchability etc

                                if (ucc.type === SpintrTypes.UberContentSectionType.File) {
                                    const inlineFilePattern =
                                        /<div><div><span class="redactorSingleFile">(.+?)<\/span><\/div>(.+?)<div><span class="redactorSingleFile">(.+?)<\/span><\/div><\/div>/gim;

                                    const fileMatches = (ucc.content as string).match(inlineFilePattern);
                                    if (fileMatches) {
                                        for (const match of fileMatches) {
                                            const fileId = parseInt(match.split(":")[1].split("@")[0], 10);

                                            if (!page.attachedFiles.some((attached) => attached === fileId)) {
                                                page.attachedFiles.push(fileId);
                                            }
                                        }
                                    }

                                    const inlineFolderPattern =
                                        /<div><div><span class="redactorInlineFolder">(.+?)<\/span><\/div>(.+?)<div><span class="redactorInlineFolder">(.+?)<\/span><\/div><\/div>/gim;

                                    const inlineFolderMatches = (ucc.content as string).match(inlineFolderPattern);
                                    if (inlineFolderMatches) {
                                        for (const match of inlineFolderMatches) {
                                            const folderId = parseInt(match.split(":")[1].split("@")[0], 10);

                                            if (!page.attachedFolders.some((attached) => attached.id === folderId)) {
                                                page.attachedFolders.push({
                                                    id: folderId,
                                                });
                                            }
                                        }
                                    }

                                    // TODO: Remove removed searches from attachedSharePointListSearches
                                    // Kinda risky because the html could've changed slightly when customers have moved them manually in classic
                                    const inlineSharePointListSearchPattern =
                                        /<div><span class="redactorInlineFolder sharePoint">(.+?)<\/span><\/div>(.+?)<div><span class="redactorInlineFolder">(.+?)<\/span><\/div>/gim;

                                    const inlineSharePointListSearchMatches = (ucc.content as string).match(
                                        inlineSharePointListSearchPattern
                                    );
                                    if (inlineSharePointListSearchMatches) {
                                        for (const match of inlineSharePointListSearchMatches) {
                                            const searchId = parseInt(match.split(":")[1].split("@")[0], 10);

                                            if (
                                                !page.attachedSharePointListSearches.some(
                                                    (attached) => attached.id === searchId
                                                )
                                            ) {
                                                page.attachedSharePointListSearches.push({
                                                    id: searchId,
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                page.tags = page.tagStrings;

                if (page.tags) {
                    page.tagstring = page.tags.join(", ");
                }

                // new data has key, old has id
                page.targets = page.targets.map((t) => ({
                    id: t.key || t.id,
                }));

                page.hiddentargets = page.hiddentargets.map((ht) => ({
                    id: ht.key || ht.id,
                }));

                page.followers = page.followers.map((f) => ({
                    id: f.key || f.id,
                }));

                page.publishers = page.publishers.map((p) => ({
                    id: p.key || p.id,
                }));

                page.factcheckers = page.factcheckers.map((fc) => ({
                    id: fc.key || fc.id,
                }));

                page.approvers = page.approvers.map((a) => ({
                    id: a.key || a.id,
                }));

                page.attachedExternalFiles = page.attachedExternalFiles?.map((f) => ({
                    ...f,
                    externalId: f.externalId || f.id,
                }));

                page.isAutosave = isAutosave;

                if (!page.hasCustomUrl) {
                    page.menuUrl = "";
                }

                if (saveAsDraft) {
                    const videosToBeUploaded = getVideosToBeUploaded(page.uberContent)

                    if (this.isNew) {
                        api.post(`/api/v1/pages/${page.id}`, { ...page, isDraft: true })
                            .then(async (r) => {
                                if (videosToBeUploaded) {
                                    await Promise.all(videosToBeUploaded.map((video) => {
                                        let body = new FormData();
                                        body.append("ticket", video.ticket);
                                        body.append("file", video.file);
                                        body.append("uberId", r.data.id);
                                        return api.post("/api/v1/azureupload", body);
                                    }));
                                }
                                this.props.history.push(`/pages/${r.data.id}/edit`);
                            })
                            .catch(this.catchError.bind(this));
                    } else {
                        api.post(`/api/v1/pages/${page.id}/createdraft`, page)
                            .then(async (r) => {
                                if (videosToBeUploaded) {
                                    await Promise.all(videosToBeUploaded.map((video) => {
                                        let body = new FormData();
                                        body.append("ticket", video.ticket);
                                        body.append("file", video.file);
                                        body.append("uberId", r.data.id);
                                        return api.post("/api/v1/azureupload", body);
                                    }));
                                }

                                dat.setState({
                                    isLoading: false,
                                    isSaving: false,
                                    saveSuccess: true,
                                    saveSuccessMessage: localize("DRAFT_SAVED"),
                                });
                            })
                            .catch(this.catchError.bind(this));
                    }
                } else {
                    const placedCheck = () => {
                        let placed = page.menuParent !== -1;
                     
                        if (!placed && !isAutosave) {
                            this.setState({ isLoading: false }, () => {
                                this._showDialog(`${localize("TextsidaIntePlacerad")} admin.`, () => this.postPage(page));
                            });
                        } else {
                            this.postPage(page);
                        }
                    }
                     
                    if (!isAutosave && this.hasEmptyBlocks(page)) {
                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            message: localize("VALIDATION_WARNING_PUBLISH_TEXTPAGE"),
                            onConfirm: placedCheck,
                            onDismiss: () => {
                                this.setState({ isLoading: false });
                            }
                        }));
                    } else {
                        placedCheck();
                    }                     
                }
            }
        );
    }

    private hasEmptyBlocks = (page) => {
        for (let i = 0; i < page.uberContent.uberContentRows.length; i++) {
            let item = page.uberContent.uberContentRows[i];
            for (let i = 0; i < item.uberContentColumns.length; i++) {
                if (item.uberContentColumns[i].state !== 0)
                {
                    return true;
                }
            }
        }
        return false;
    }

    private setReviewStatus = (status) => {
        this.setState({ isLoading: true }, () => {
            const obj = {
                id: this.state.page.contentReview.id,
                status: status,
                message: this.state.rejectMessage,
            };

            api.put(`/api/v1/contentreview/${this.state.page.contentReview.id}`, obj)
                .then(() => {
                    this.setState({ isLoading: false, showRejectModal: false });
                    if (status === true) {
                        fetchMenu(this.props.dispatch, this.props.currentUser);
                        this.props.history.push(`/goto/${this.state.page.id}`);
                    } else {
                        this.setState({
                            page: {
                                ...this.state.page,
                                contentReview: { ...this.state.page.contentReview, status: false },
                            },
                        });
                    }
                })
                .catch(this.catchError.bind(this));
        });
    };

    closeRejectModal = () => {
        const close = () => {
            this.setState({
                showRejectModal: false,
            });
        };

        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: close,
                })
            );
        } else {
            close();
        }
    };

    private renderRejectModal = () => {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showRejectModal}
                onDismiss={this.closeRejectModal.bind(this)}
            >
                <PopupHeader
                    text={localize("Avsla") + ' "' + this.state.page.title + '"'}
                    onClose={this.closeRejectModal.bind(this)}
                />
                <div className="page-form-view-reject-modal">
                    <TextField
                        value={this.state.rejectMessage}
                        onChange={(_ev, rejectMessage) => {
                            this.setState({ rejectMessage });
                        }}
                        multiline={true}
                        label={localize("Kommentar_big")}
                    />
                    <DefaultButton onClick={this.closeRejectModal.bind(this)}>{localize("Avbryt")}</DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.setReviewStatus(false);
                        }}
                    >
                        {localize("Avsla")}
                    </PrimaryButton>
                </div>
            </Modal>
        );
    };

    private renderContentReview = () => {
        const { page } = this.state;
        const isCreator = this.props.currentUser.id === page.publisherId;

        return (
            <div className="contentReviewBar">
                {(this.props.isAdmin || this.props.isEditor) &&
                page.contentReview &&
                page.contentReview.status !== true &&
                page.contentReview.status !== false ? (
                    <div className="container">
                        <div className="adminBar">
                            <div className="left">{localize("GranskningAvSida")}</div>
                            <div className="right">
                                <PrimaryButton
                                    onClick={() => {
                                        this.setReviewStatus(true);
                                    }}
                                >
                                    {localize("Godkann")}
                                </PrimaryButton>
                                <DefaultButton
                                    onClick={() => {
                                        this.setState({ showRejectModal: true });
                                    }}
                                >
                                    {localize("Avsla")}
                                </DefaultButton>
                            </div>
                        </div>
                    </div>
                ) : (
                    page.contentReview &&
                    page.contentReview.status === false &&
                    isCreator &&
                    page.contentReview.message &&
                    page.contentReview.message.length > 0 && (
                        <div className="container">
                            <div className="userBar">
                                <div className="messageHeader">
                                    {localize("KommentarFran")} {page.contentReview.reviewedByName}:
                                </div>
                                <div className="message">{page.contentReview.message}</div>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    };

    getFormFooterButtons() {
        if (this.state.isTemplate) {
            return [
                {
                    text: localize("RemoveLayout"),
                    onClick: () => {
                        this.setState({
                            displayDeleteDialog: true,
                        });
                    },
                },
            ];
        } else {
            return [
                ...(canUserDeletePage(
                    this.props.privileges,
                    this.props.currentUser,
                    this.props.instance,
                    this.state.page.id
                )
                    ? [
                          {
                              text: this.state.page.isDeleted ? localize("Aterstall") : localize("TaBort"),
                              onClick: () => {
                                  if (this.state.page.isDeleted) {
                                      this.setState(
                                          {
                                              isLoading: true,
                                          },
                                          () => {
                                              api.put("/api/pages/" + this.state.page.id + "/toggledelete").then(() => {
                                                  this.setState({
                                                      isLoading: false,
                                                      page: {
                                                          ...this.state.page,
                                                          isDeleted: false,
                                                      },
                                                  });

                                                  fetchMenu(this.props.dispatch, this.props.currentUser);
                                              });
                                          }
                                      );
                                  } else {
                                      this.setState({
                                          displayDeleteDialog: true,
                                      });
                                  }
                              },
                          },
                      ]
                    : []),
                ...(this.state.page.id !== 0
                    ? [
                          {
                              text: localize("SaveAsLayout"),
                              onClick: () => {
                                  api.post("/api/pages/template/" + this.state.page.id).then(() => {
                                      this.setState(
                                          {
                                              isLoading: true,
                                              hideDialog: false,
                                              dialogMessage: localize("PageSavedAsLayout"),
                                          },
                                          () => {
                                              this.fetchCustomTemplates();
                                              this.setState({ isLoading: false });
                                          }
                                      );
                                  });
                              },
                          },
                      ]
                    : []),
                ...(this.state.page.menuUrl !== "" && this.state.page.id !== 0
                    ? [
                          {
                              text: `${localize("Besok_singular")} ${localize("Sida")}`,
                              onClick: () => {
                                  this.props.history.push("/goto/" + this.state.page.id);
                              },
                          },
                      ]
                    : []),
            ];
        }
    }

    private onFileUploadClick = () => {
        this.setState({
            isFilePickerOpen: true,
        });
    };

    private onFileDeleteClick = (item) => {
        const attachedFiles = [...(this.state.page.attachedFiles ? this.state.page.attachedFiles : [])].filter(
            (f) => f !== item.id
        );

        if (this.state.page.attachedFiles.includes(item.id)) {
            this.props.dispatch(removeAttachedFile(item.id));
        }

        const attachedFolders = [...(this.state.page.attachedFolders ? this.state.page.attachedFolders : [])].filter(
            (f) => f.id !== item.id
        );

        if (this.state.page.attachedFolders.find((af) => af.id == item.id)) {
            this.props.dispatch(removeAttachedFolder(item.id));
        }

        this.setState({
            page: {
                ...this.state.page,
                attachedFiles,
                attachedFolders,
            },
        });
    };

    private onExternalFileDeleteClick = (item) => {
        const attachedExternalFiles = this.state.page.attachedExternalFiles.filter(
            (f) => f.externalId !== item.id && f.id !== item.id
        ); // newly added have id, old have externalId

        this.setState({
            page: {
                ...this.state.page,
                attachedExternalFiles,
            },
        });
    };

    private onCaneaDeleteClick = (item) => {
        const caneaFiles = this.state.page.caneaFiles.filter((f) => f.id !== item.id);

        this.setState({
            page: {
                ...this.state.page,
                caneaFiles,
            },
        });
    };

    private onHeaderImageChange = (result) => {
        console.log(result);
        this.setState({
            page: {
                ...this.state.page,
                headerImageUrl: result.thumbnailUrl,
                headerImageTicket: {
                    remove: !result.ticket && !result.isUnsplash,
                    ticket: result.ticket,
                    mediaflowId: result.externalMediaflowId,
                    hotlink: result.isUnsplash,
                    image: result.thumbnailUrl,
                },
            },
        });
    };

    private toggleDisplayPreview = () => {
        this.setState(
            (prevState) => ({
                displayPreview: !prevState.displayPreview,
            }),
            () => {
                scrollToTop();
            }
        );
    };

    private renderInfoPanel = () => (
        <PageInfoPanel
            uberId={this.state.page.id}
            publishers={this.state.page.publishers}
            displayTags={true}
            displayLastEdited={true}
            displayReach={true}
            displayPublishers={this.state.page.showPublisher}
            alwaysDisplayMeta={!this.state.displayPreview}
            isWide={this.state.page.template === 2}
            tags={this.state.page.tagStrings}
            displayFiles={
                !this.state.displayPreview ||
                this.state.page.attachedFiles.length > 0 ||
                this.state.page.attachedFolders.length > 0
            }
            editMode={!this.state.displayPreview}
            files={[...this.props.attachedFolders, ...this.props.attachedFiles]}
            externalFiles={this.state.page.attachedExternalFiles}
            displayExternalFiles={this.state.page.attachedExternalFiles?.length > 0 && this.props.enableExternalFiles}
            onFileUploadClick={this.state.displayPreview ? undefined : this.onFileUploadClick}
            onFileDeleteClick={this.state.displayPreview ? undefined : this.onFileDeleteClick}
            onExternalFileDeleteClick={this.state.displayPreview ? undefined : this.onExternalFileDeleteClick}
            displayCaneaFiles={this.state.page.caneaFiles?.length > 0 && this.props.enableCanea}
            caneaFiles={this.state.page.caneaFiles}
            onCaneaDeleteClick={this.state.displayPreview ? undefined : this.onCaneaDeleteClick}
            displayLinks={this.state.displayPreview ? this.state.page.links.length > 0 : true}
            links={this.state.page.links}
            onLinksChange={(links: any) => {
                this.setState({
                    page: {
                        ...this.state.page,
                        links: links,
                    },
                });
            }}
        />
    );

    private hasModelChanged(): boolean {
        return (
            this.referenceModel &&
            (this.referenceModel.title !== this.state.page.title ||
                JSON.stringify(this.referenceModel.uberContent) !== JSON.stringify(this.state.page.uberContent))
        );
    }

    private autosaveNeeded(): boolean {
        if (this.state.isAutosaving) {
            return false;
        }

        return this.hasModelChanged();
    }

    private onAutosave() {
        if ((!this.props.isAdmin && !this.props.isEditor) || !this.autosaveNeeded()) {
            return;
        }

        this.referenceModel = { ...this.state.page };

        const hasUberContent = this.referenceModel.uberContent &&
            this.referenceModel.uberContent.uberContentRows &&
            this.referenceModel.uberContent.uberContentRows.find(row => row.uberContentColumns.find(column => !!column.content));

        if (!this.referenceModel.title &&
            !hasUberContent) {
            return;
        }

        if (!this.referenceModel.id) {
            this.save(false, true);
            return;
        }

        this.setState({ isAutosaving: true }, async () => {
            try {
                const version = await autosave({
                    headline: this.referenceModel.title || "",
                    id: this.state.autosaveId,
                    preamble: "",
                    text: "",
                    uberContent: this.referenceModel.uberContent,
                    uberId: this.referenceModel.id,
                });

                this.setState({
                    autosaveId: version.id,
                    isAutosaving: false,
                    lastAutosave: new Date(),
                });
            } catch {
                this.setState({ isAutosaving: false });
            }
        });
    }

    renderPreview = () => (
        <div className="PageFormView-preview">
            <div className="info-bar with-bottom-spacing">
                <Label size="body-2">{localize("PREVIEW_INFO")}</Label>
                <PrimaryButton onClick={this.toggleDisplayPreview}>
                    {localize("GaTillbakaTillRedigering")}
                </PrimaryButton>
            </div>
            {!!this.state.page.headerImageUrl ? (
                <div>
                    <ContentImageViewerAndSelector
                        imageUrl={this.state.page.headerImageUrl}
                        editMode={false}
                        cropAspect={SpintrTypes.ImageCropAspect.UltraWide}
                        autoHeight
                        blur
                    />
                </div>
            ) : null}
            <PageHeader title={this.state.page.title} size="big" />
            {this.state.page.template !== 4 ? (
                <UberContentView
                    uberContent={this.state.page.uberContent}
                    attachedFiles={this.state.page.attachedFiles}
                    attachedFolders={this.state.page.attachedFolders}
                />
            ) : (
                <TinyFormattedContent
                    content={
                        this.state.page.content +
                        "<h2>" +
                        localize("TextPageMsystemH1") +
                        "</h2>" +
                        this.state.page.content +
                        "<br /><h2>" +
                        localize("TextPageMsystemH2") +
                        "</h2>" +
                        this.state.page.content2 +
                        "<br /><h2>" +
                        localize("TextPageMsystemH3") +
                        "</h2>" +
                        this.state.page.content3 +
                        "<br /><h2>" +
                        localize("TextPageMsystemH4") +
                        "</h2>" +
                        this.state.page.content4 +
                        "<br />" +
                        this.state.page.content5 +
                        "<br />"
                    }
                />
            )}
            {(this.state.page.template === 2 || this.props.isSmallViewMode) && this.state.page.template !== 3 && (
                <div>
                    <Separator className="content-separator" />
                    {this.renderInfoPanel()}
                </div>
            )}
        </div>
    );

    renderForm = () => (
        <SplitForm
            headerTitle={
                this.state.isTemplate
                    ? localize("RedigeraMall")
                    : this.isNew
                    ? localize("SkapaTextSida")
                    : localize("RedigeraTextSida")
            }
            additionalSettings={[
                {
                    title: localize("Placering"),
                    enabled: !this.state.isTemplate,
                    content: (
                        <Stack styles={stackStyles}>
                            <WebStructure
                                page={{
                                    id: this.state.page.id,
                                    menuId: this.state.page.menuId,
                                    menuParent: this.state.page.menuParent,
                                    menuPosition: this.state.page.menuPosition,
                                    title: this.state.page.title,
                                    groupId: this.state.page.groupId,
                                }}
                                onChange={(result) => {
                                    this.setState({
                                        page: {
                                            ...this.state.page,
                                            menuParent: result.menuParent,
                                            menuPosition: result.menuPosition,
                                        },
                                    });
                                }}
                            />
                        </Stack>
                    ),
                },
                {
                    title: localize("Mallar"),
                    content: (
                        <Stack styles={stackStyles}>
                            <div>
                                <FormSection title={localize("PredefinedTemplates")}>
                                    {this.renderTemplateSvg(1)}
                                    {this.renderTemplateSvg(2)}
                                    {this.renderTemplateSvg(3)}
                                    {this.renderTemplateSvg(4)}
                                </FormSection>
                            </div>
                            {this.state.customTemplates && this.state.customTemplates.length > 0 && (
                                <div className="PageFormView-CustomTemplates">
                                    <FormSection title={localize("CustomTemplates")}>
                                        {this.state.customTemplates.map((ct, index) => {
                                            return (
                                                <div className="PageFormView-CustomTemplate" key={index}>
                                                    {this.renderTemplateSvg(ct.template, ct)}
                                                    <div className="PageFormView-CustomTemplateInfo">
                                                        <Label color="dark-grey" size="body-2" weight="medium">
                                                            {ct.name + ", " + moment(ct.date).format("L")}
                                                        </Label>
                                                        <Link to={`/pages/${ct.id}/edit?isTemplate=true`}>
                                                            <Label weight="medium" size="body-2" color="light-blue">
                                                                {localize("EditTheLayout")}
                                                            </Label>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </FormSection>
                                </div>
                            )}
                        </Stack>
                    ),
                },
                {
                    title: localize("Metadata"),
                    enabled: this.state.page.template !== 3,
                    content: this.renderInfoPanel(),
                },
                {
                    title: localize("Installningar"),
                    content: (
                        <Stack styles={stackStyles}>
                            <FormSection title={localize("Publicering")}>
                                <FormControl>
                                    <SpintrDatePicker
                                        label={localize("Fran")}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        strings={getDatePickerLanguageStrings()}
                                        formatDate={formatDatePickerDate}
                                        placeholder={localize("ValjDatum")}
                                        ariaLabel={localize("ValjDatum")}
                                        value={this.state.page.publishDate}
                                        maxDate={
                                            !!this.state.page.publishEndDate ? this.state.page.publishEndDate : null
                                        }
                                        isRequired={true}
                                        aria-required={true}
                                        onChangeHandler={(date: Date) => {
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    publishDate: date,
                                                },
                                            });
                                        }}
                                    />
                                    <SpintrDatePicker
                                        label={localize("Till")}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        minDate={this.state.page.publishDate}
                                        strings={getDatePickerLanguageStrings()}
                                        formatDate={formatDatePickerDate}
                                        placeholder={localize("ValjDatum")}
                                        ariaLabel={localize("ValjDatum")}
                                        value={!!this.state.page.publishEndDate ? this.state.page.publishEndDate : null}
                                        onChangeHandler={(date: Date) => {
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    publishEndDate: date,
                                                },
                                            });
                                        }}
                                    />
                                    {this.state.page.publishEndDate && (
                                        <DefaultButton
                                            className={"removeDateButton"}
                                            text={localize("TaBortDatum")}
                                            onClick={() => {
                                                this.setState({
                                                    page: {
                                                        ...this.state.page,
                                                        publishEndDate: null,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </FormControl>
                                {this.state.page.publishEndDate && (
                                    <FormControl>
                                        <Checkbox
                                            label={localize("AvpubliceraUndersidorRekursivt")}
                                            checked={this.state.page.unpublishChildrenOnEndDate}
                                            name="unpublishChildrenOnEndDate"
                                            onChange={this.onChangeCheckbox}
                                        />
                                    </FormControl>
                                )}
                            </FormSection>
                            {this.props.instance.get("enableTextpageExpires") && (
                                <FormSection title={localize("Forfallodatum")}>
                                    <FormControl>
                                        <Dropdown
                                            label={localize("ForfallerInfo")}
                                            selectedKey={
                                                this.state.page.expiresInterval ? this.state.page.expiresInterval : 0
                                            }
                                            onChange={(_e, v) => {
                                                this.setState((state) => ({
                                                    page: {
                                                        ...state.page,
                                                        expires: v.key.toString(),
                                                        expiresInterval: Number(v.key),
                                                    },
                                                }));
                                            }}
                                            options={[
                                                { key: 0, text: localize("Aldrig") },
                                                { key: 1, text: localize("VarjeManad") },
                                                { key: 2, text: localize("VarannanManad") },
                                                {
                                                    key: 3,
                                                    text: localize("VarSjätteManad"),
                                                },
                                                { key: 4, text: localize("VarjeAr") },
                                                {
                                                    key: 5,
                                                    text: localize("ON_THE_PUBLISH_END_DATE"),
                                                },
                                                { key: 6, text: localize("ValjDatum") },
                                            ]}
                                            styles={{ dropdown: { width: 200 } }}
                                        />
                                    </FormControl>
                                    {this.state.page.expiresInterval === 6 && (
                                        <FormControl>
                                            <SpintrDatePicker
                                                label={localize("ValjDatum")}
                                                firstDayOfWeek={DayOfWeek.Monday}
                                                strings={getDatePickerLanguageStrings()}
                                                formatDate={formatDatePickerDate}
                                                placeholder={localize("ValjDatum")}
                                                ariaLabel={localize("ValjDatum")}
                                                value={this.state.page.expireDate}
                                                isRequired={true}
                                                aria-required={true}
                                                onChangeHandler={(date: Date) => {
                                                    this.setState({
                                                        page: {
                                                            ...this.state.page,
                                                            expireDate: date,
                                                        },
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                </FormSection>
                            )}
                            <FormSection title={localize("Taggar")}>
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        required={this.props.requiredTagCount > 0}
                                        hideImage={true}
                                        enableNewItems={true}
                                        items={this.state.page.tags.map((tag) => {
                                            if (typeof tag === "string") {
                                                return {
                                                    name: tag,
                                                };
                                            } else {
                                                return {
                                                    name: !tag.text ? tag.name : tag.text,
                                                    imageUrl: !tag.image ? tag.imageUrl : tag.image,
                                                };
                                            }
                                        })}
                                        label={localize("SokBlandTaggar")}
                                        onChange={(tags) => {
                                            const tagStrings = tags.map((tag) => tag.name);
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    tags,
                                                    tagStrings,
                                                },
                                            });
                                        }}
                                        showTags
                                    />
                                </FormControl>
                            </FormSection>
                            <FormSection title={localize("Ansvarig_Utgivare")}>
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        items={this.state.page.publishers.map((p) => {
                                            return {
                                                ...p,
                                                text: !p.text ? p.name : p.text,
                                                imageUrl: !p.image ? p.imageUrl : p.image,
                                                subText: p.info,
                                            };
                                        })}
                                        types={[
                                            SpintrTypes.UberType.User,
                                            SpintrTypes.UberType.TargetGroup,
                                            SpintrTypes.UberType.Role,
                                            SpintrTypes.UberType.Department,
                                            SpintrTypes.UberType.Office,
                                        ]}
                                        label={localize("Ansvarig_Utgivare")}
                                        onChange={(publishers) => {
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    publishers: publishers.map((p) => {
                                                        return {
                                                            ...p,
                                                            text: p.name,
                                                            image: p.imageUrl,
                                                            info: p.subText,
                                                        };
                                                    }),
                                                },
                                            });
                                        }}
                                    />
                                    <Checkbox
                                        label={localize("VisaAnsvarigUtgivare")}
                                        checked={this.state.page.showPublisher}
                                        name="showPublisher"
                                        onChange={this.onChangeCheckbox}
                                    />
                                </FormControl>
                            </FormSection>
                            <FormSection title="Feedback">
                                <FormControl>
                                    <Checkbox
                                        label={localize("ALLOW_FEEDBACK")}
                                        checked={this.state.page.allowFeedback}
                                        name="allowFeedback"
                                        onChange={this.onChangeCheckbox}
                                    />
                                </FormControl>
                            </FormSection>
                            {!!this.state.isForGroupId ? null : (
                                <div>
                                    <FormSection title={localize("Granskning")}>
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                items={this.state.page.factcheckers.map((p) => {
                                                    return {
                                                        ...p,
                                                        name: !p.text ? p.name : p.text,
                                                        imageUrl: !p.image ? p.imageUrl : p.image,
                                                    };
                                                })}
                                                types={[
                                                    SpintrTypes.UberType.User,
                                                    SpintrTypes.UberType.TargetGroup,
                                                    SpintrTypes.UberType.Role,
                                                    SpintrTypes.UberType.Department,
                                                    SpintrTypes.UberType.Office,
                                                ]}
                                                label={localize("Faktagranskare")}
                                                onChange={(factcheckers) => {
                                                    this.setState({
                                                        page: {
                                                            ...this.state.page,
                                                            factcheckers,
                                                        },
                                                    });
                                                }}
                                            />
                                            <FormTokenizedObjectInput
                                                items={this.state.page.approvers.map((p) => {
                                                    return {
                                                        ...p,
                                                        name: !p.text ? p.name : p.text,
                                                        imageUrl: !p.image ? p.imageUrl : p.image,
                                                    };
                                                })}
                                                types={[
                                                    SpintrTypes.UberType.User,
                                                    SpintrTypes.UberType.TargetGroup,
                                                    SpintrTypes.UberType.Role,
                                                    SpintrTypes.UberType.Department,
                                                    SpintrTypes.UberType.Office,
                                                ]}
                                                label={localize("GodkannaraAvInnehall")}
                                                onChange={(approvers) => {
                                                    this.setState({
                                                        page: {
                                                            ...this.state.page,
                                                            approvers,
                                                        },
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </FormSection>
                                    <FormSection title={localize("Kommentarer_Stor")}>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("TillatKommentarer")}
                                                checked={this.state.page.allowComments}
                                                name="allowComments"
                                                onChange={this.onChangeCheckbox}
                                            />
                                        </FormControl>
                                    </FormSection>
                                    <FormSection title={localize("Meny")}>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("SorteraUndersidorAlfabetiskt")}
                                                checked={this.state.page.sortChildrenAlphabetically}
                                                name="sortChildrenAlphabetically"
                                                onChange={this.onChangeCheckbox}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("ValjAnnanMenytitel")}
                                                checked={this.state.page.hasCustomMenuTitle}
                                                name="hasCustomMenuTitle"
                                                onChange={this.onChangeCheckbox}
                                            />
                                            {!!this.state.page.hasCustomMenuTitle ? (
                                                <FormControl hasTopSpacing={true}>
                                                    <TextField
                                                        label={localize("MenyTitel")}
                                                        value={
                                                            !!this.state.page.menuTitle ? this.state.page.menuTitle : ""
                                                        }
                                                        onChange={(
                                                            ev: React.FormEvent<HTMLInputElement>,
                                                            newValue?: string
                                                        ) => {
                                                            this.setState({
                                                                page: {
                                                                    ...this.state.page,
                                                                    menuTitle: newValue,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                            ) : null}
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("ValjAnnanUrl")}
                                                checked={this.state.page.hasCustomUrl}
                                                name="hasCustomUrl"
                                                onChange={this.onChangeCheckbox}
                                            />
                                            {!!this.state.page.hasCustomUrl ? (
                                                <FormControl hasTopSpacing={true}>
                                                    <TextField
                                                        label={localize("Url")}
                                                        value={!!this.state.page.menuUrl ? this.state.page.menuUrl : ""}
                                                        onChange={(
                                                            ev: React.FormEvent<HTMLInputElement>,
                                                            newValue?: string
                                                        ) => {
                                                            this.setState({
                                                                page: {
                                                                    ...this.state.page,
                                                                    menuUrl: newValue,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                            ) : null}
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("DoljMenylankarTillSidan")}
                                                checked={this.state.page.hideFromMenu}
                                                name="hideFromMenu"
                                                onChange={this.onChangeCheckbox}
                                            />
                                        </FormControl>
                                    </FormSection>
                                    <FormSection title={localize("Notiser")}>
                                        <FormTokenizedObjectInput
                                            items={this.state.page.followers.map((p) => {
                                                return {
                                                    ...p,
                                                    text: !p.text ? p.name : p.text,
                                                    imageUrl: !p.image ? p.imageUrl : p.image,
                                                };
                                            })}
                                            types={[
                                                SpintrTypes.UberType.User,
                                                SpintrTypes.UberType.TargetGroup,
                                                SpintrTypes.UberType.Role,
                                                SpintrTypes.UberType.Department,
                                                SpintrTypes.UberType.Office,
                                            ]}
                                            label={localize("AktiveraNotiserForFoljandeMottagare")}
                                            onChange={(followers) => {
                                                this.setState({
                                                    page: {
                                                        ...this.state.page,
                                                        followers,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormSection>
                                    <div className="rights">
                                        {this.state.page.uberDirectInheritedFrom > 0 && (
                                            <>
                                                <div className="locked" />
                                                <Label className="locked-text">
                                                    {localize("InstallingarnaHarArvts")}{" "}
                                                    <Link to={`/pages/${this.state.page.uberDirectInheritedFrom}/edit`}>
                                                        {localize("Harifran")}
                                                    </Link>
                                                </Label>
                                            </>
                                        )}
                                        <FormSection title={localize("SynligFor")}>
                                            <FormControl>
                                                <FormTokenizedObjectInput
                                                    showAllWhenEmpty
                                                    items={this.state.page.targets.map((p) => {
                                                        return {
                                                            ...p,
                                                            text: !p.text ? p.name : p.text,
                                                            imageUrl: !p.image ? p.imageUrl : p.image,
                                                            key: p.id,
                                                        };
                                                    })}
                                                    types={[
                                                        SpintrTypes.UberType.TargetGroup,
                                                        SpintrTypes.UberType.Role,
                                                        SpintrTypes.UberType.Department,
                                                        SpintrTypes.UberType.Office,
                                                        ...(this.props.instance.get("enableCompanyLevel")
                                                            ? [SpintrTypes.UberType.Company]
                                                            : []),
                                                    ]}
                                                    label={localize("RiktadTillBeskrivning")}
                                                    onChange={(targets) => {
                                                        this.setState({
                                                            page: {
                                                                ...this.state.page,
                                                                targets: targets.map((e) => {
                                                                    return {
                                                                        //@ts-ignore
                                                                        key: e.id || e.key,
                                                                        name: e.name,
                                                                        imageUrl: e.imageUrl,
                                                                        info: e.subText,
                                                                        //@ts-ignore
                                                                        id: e.id || e.key,
                                                                        type: e.type,
                                                                    };
                                                                }),
                                                            },
                                                        });
                                                    }}
                                                />
                                                <Checkbox
                                                    label={localize("InstallningenGallerForUndersidorRekursiv")}
                                                    checked={this.state.page.childrenHasSameUberDirect}
                                                    name="childrenHasSameUberDirect"
                                                    onChange={this.onChangeCheckbox}
                                                />
                                            </FormControl>
                                        </FormSection>
                                        <FormSection title={localize("DoldFor")}>
                                            <FormControl>
                                                <FormTokenizedObjectInput
                                                    items={this.state.page.hiddentargets.map((p) => {
                                                        return {
                                                            ...p,
                                                            text: !p.text ? p.name : p.text,
                                                            imageUrl: !p.image ? p.imageUrl : p.image,
                                                            key: p.id,
                                                        };
                                                    })}
                                                    types={[
                                                        SpintrTypes.UberType.TargetGroup,
                                                        SpintrTypes.UberType.Role,
                                                        SpintrTypes.UberType.Department,
                                                        SpintrTypes.UberType.Office,
                                                        ...(this.props.instance.get("enableCompanyLevel")
                                                            ? [SpintrTypes.UberType.Company]
                                                            : []),
                                                    ]}
                                                    label={localize("DoldForBeskrivning")}
                                                    onChange={(hiddentargets) => {
                                                        this.setState({
                                                            page: {
                                                                ...this.state.page,
                                                                hiddentargets: hiddentargets.map((e) => {
                                                                    return {
                                                                        //@ts-ignore
                                                                        key: e.id || e.key,
                                                                        name: e.name,
                                                                        imageUrl: e.imageUrl,
                                                                        info: e.subText,
                                                                        //@ts-ignore
                                                                        id: e.id || e.key,
                                                                        type: e.type,
                                                                    };
                                                                }),
                                                            },
                                                        });
                                                    }}
                                                />
                                                <Checkbox
                                                    label={localize("InstallningenGallerForUndersidor")}
                                                    checked={this.state.page.childrenHasSameUberDirectHidden}
                                                    name="childrenHasSameUberDirectHidden"
                                                    onChange={this.onChangeCheckbox}
                                                />
                                            </FormControl>
                                        </FormSection>
                                    </div>
                                    <FormSection title={localize("Sprak")}>
                                        <FormControl>
                                            <Dropdown
                                                label={localize("TEXTPAGE_LANGUAGE_INFO")}
                                                selectedKey={this.state.page.language ? this.state.page.language : ""}
                                                onChange={(
                                                    _event: React.FormEvent<HTMLDivElement>,
                                                    item: IDropdownOption
                                                ) => {
                                                    this.setState((state) => ({
                                                        page: {
                                                            ...state.page,
                                                            language: item.key.toString(),
                                                        },
                                                    }));
                                                }}
                                                options={getLanguageOptions()}
                                                styles={{ dropdown: { width: 300 } }}
                                            />
                                        </FormControl>
                                    </FormSection>
                                </div>
                            )}
                        </Stack>
                    ),
                },
                {
                    title: localize("Ledningssystem"),
                    enabled: !!this.props.apps.items.find(
                        (a) => a.id === SpintrTypes.SpintrApp.DeviationManager && a.enabled
                    ),
                    content: (
                        <Stack styles={stackStyles}>
                            <FormSection title={localize("Avvikelser")}>
                                <FormControl>
                                    <Checkbox
                                        label={localize("AktiveraAvvikelser")}
                                        checked={this.state.page.modules.msystem.allowDeviations}
                                        onChange={(
                                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                            checked?: boolean
                                        ) => {
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    modules: {
                                                        ...this.state.page.modules,
                                                        msystem: {
                                                            ...this.state.page.modules.msystem,
                                                            allowDeviations: checked,
                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </FormSection>
                            <FormSection title={localize("Forbattringar")}>
                                <FormControl>
                                    <Checkbox
                                        label={localize("AktiveraForbattringar")}
                                        checked={this.state.page.modules.improvements.allowImprovements}
                                        onChange={(
                                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                            checked?: boolean
                                        ) => {
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    modules: {
                                                        ...this.state.page.modules,
                                                        improvements: {
                                                            ...this.state.page.modules.improvements,
                                                            allowImprovements: checked,
                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </FormSection>
                        </Stack>
                    ),
                },
                {
                    title:
                        localize("Versioner") +
                        " " +
                        localize("Och_small") +
                        " " +
                        localize("Utkast_Plural").toLowerCase(),
                    enabled: !this.state.isTemplate && this.state.page.id > 0,
                    content: (
                        <Stack>
                            <ContentVersionsList
                                uberId={this.state.page.id}
                                onContentVersionSelected={(data) => {
                                    let newPage = {
                                        ...this.state.page,
                                        title: data.Headline,
                                        preamble: data.Introduction,
                                        content: data.Text,
                                        uberContent: !!data.UberContent
                                            ? {
                                                  ...this.state.page.uberContent,
                                                  uberContentRows: data.UberContent.uberContentRows,
                                              }
                                            : null,
                                    };

                                    this.setState({
                                        isLoading: true
                                    }, () => {
                                        this.initPage(newPage, () => {
                                            this._showDialog(localize("VERISON_APPLIED"));
                                        });
                                    });
                                }}
                            />
                        </Stack>
                    ),
                },
            ]}
        >
            <Stack styles={stackStyles}>
                <div
                    style={{
                        marginBottom: Style.getSpacingStr(3),
                    }}
                >
                    <ContentImageViewerAndSelector
                        renderSizeString={() => {
                            return `${localize("OptimalBildStorlek")}: 1000x170px <br />`;
                        }}
                        cropImage
                        height={170}
                        cropAspect={1000 / 170}
                        imageUrl={this.state.page.headerImageUrl}
                        editMode={true}
                        onChange={this.onHeaderImageChange}
                        enableMediaflow={this.props.enableMediaflow}
                        enableUnsplash={this.props.enableUnsplash}
                        blur
                    />
                </div>
                <div
                    style={{
                        marginBottom: Style.getSpacingStr(3),
                    }}
                >
                    <FormControl>
                        <TextField
                            data-cy="textpage-headline"
                            ariaLabel={localize("SkrivEnRubrik")}
                            defaultValue={this.state.page.title}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    event.stopPropagation(); // Prevents page from reloading
                                    event.preventDefault();
                                }
                            }}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, name?: string) => {
                                const debouncedHandle = debounce(() => {
                                    this.handleTitleChange(name);
                                }, 500);

                                debouncedHandle();
                            }}
                            label={localize("Rubrik")}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                </div>
                {this.state.page.template !== 4 ? (
                    <EditableContentSections
                        contentRows={this.state.page.uberContent.uberContentRows}
                        onChange={(newUberContentRows) => {
                            this.setState({
                                page: {
                                    ...this.state.page,
                                    uberContent: {
                                        ...this.state.page.uberContent,
                                        uberContentRows: newUberContentRows,
                                    },
                                },
                                isDirty: true,
                            });
                        }}
                        createBlockIfEmpty={this.state.page && this.state.page.id === 0}
                    />
                ) : (
                    <div>
                        <FormControl>
                            <FabricLabel>{localize("TextPageMsystemH1")}</FabricLabel>
                            <TinyEditorComponent
                                id="h1"
                                content={this.state.page.content}
                                saveOnClickOutside={true}
                                onSave={(content) => {
                                    this.setState((prevState) => ({
                                        page: {
                                            ...prevState.page,
                                            content: content,
                                        },
                                        isDirty: true,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FabricLabel>{localize("TextPageMsystemH2")}</FabricLabel>
                            <TinyEditorComponent
                                id="h2"
                                content={this.state.page.content2}
                                saveOnClickOutside={true}
                                onSave={(content) => {
                                    this.setState((prevState) => ({
                                        page: {
                                            ...prevState.page,
                                            content2: content,
                                        },
                                        isDirty: true,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FabricLabel>{localize("TextPageMsystemH3")}</FabricLabel>
                            <TinyEditorComponent
                                id="h3"
                                content={this.state.page.content3}
                                saveOnClickOutside={true}
                                onSave={(content) => {
                                    this.setState((prevState) => ({
                                        page: {
                                            ...prevState.page,
                                            content3: content,
                                        },
                                        isDirty: true,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FabricLabel>{localize("TextPageMsystemH4")}</FabricLabel>
                            <TinyEditorComponent
                                id="h4"
                                content={this.state.page.content4}
                                saveOnClickOutside={true}
                                onSave={(content) => {
                                    this.setState((prevState) => ({
                                        page: {
                                            ...prevState.page,
                                            content4: content,
                                        },
                                        isDirty: true,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FabricLabel>{localize("Ovrigt")}</FabricLabel>
                            <TinyEditorComponent
                                id="h5"
                                content={this.state.page.content5}
                                saveOnClickOutside={true}
                                onSave={(content) => {
                                    this.setState((prevState) => ({
                                        page: {
                                            ...prevState.page,
                                            content5: content,
                                        },
                                        isDirty: true,
                                    }));
                                }}
                            />
                        </FormControl>
                    </div>
                )}
            </Stack>
        </SplitForm>
    );

    render() {
        if (this.state.isLoading || !this.state.page) {
            return <Loader />;
        }

        let adminEditView = false;

        if (window.location.href.indexOf("admin") > -1) {
            adminEditView = true;
        }

        return (
            <ContentWithInfoPanel
                visagePageStyle
                template={this.state.displayPreview ? this.state.page.template : 0}
                renderInfoPanel={this.renderInfoPanel}
                noMaxWidth
            >
                <div>
                    {!this.state.isLoading && this.state.page && (
                        <Autosaver model={this.state.page} onAutosave={this.onAutosave} />
                    )}
                    <div>
                        <div
                            className={classnames("PageFormView", {
                                "tinymce-dirty": this.state.isDirty,
                                "apply-padding": adminEditView
                            })}
                        >
                            <FormFooterBar
                                top={!this.props.isSmallViewMode}
                                // autosaveText={
                                //     !this.state.lastAutosave
                                //         ? null
                                //         : localize("Autosparat") + " " + moment(this.state.lastAutosave).format("HH:mm")
                                // }
                                buttons={this.getFormFooterButtons()}
                                saveText={
                                    this.state.isTemplate
                                        ? localize("Spara") + " " + localize("Mall").toLowerCase()
                                        : localize("Publicera")
                                }
                                onRenderLeft={
                                    this.props.isSmallViewMode // || this.state.lastAutosave
                                        ? undefined
                                        : () => (
                                              <Breadcrumbs
                                                  displayInstance
                                                  items={[
                                                      ...(adminEditView
                                                          ? [
                                                                {
                                                                    text: localize("Administration"),
                                                                    key: 2,
                                                                    link: "/admin",
                                                                },
                                                                {
                                                                    text: localize("Textsidor"),
                                                                    key: 3,
                                                                    link: "/admin/pages",
                                                                },
                                                            ]
                                                          : []),
                                                      {
                                                          text: this.state.isTemplate
                                                              ? localize("RedigeraMall")
                                                              : this.isNew
                                                              ? localize("SkapaTextSida")
                                                              : localize("RedigeraTextSida"),
                                                          key: 4,
                                                          link: window.location.pathname,
                                                      },
                                                  ]}
                                              />
                                          )
                                }
                                onSaveClick={this.handleSaveClick}
                                onSaveDraftClick={this.state.isTemplate ? null : this.handleSaveDraftClick}
                                onCancelClick={this.handleCancelClick}
                                onPreviewClick={this.toggleDisplayPreview}
                                confirmSave={localize("ArDuSakerPaAttDuVillX").replace(
                                    "{{X}}",
                                    localize("PubliceraDetta").toLowerCase()
                                )}
                            />
                            <div>
                                {this.state.saveSuccess && (
                                    <SuccessMessagebar
                                        message={this.state.saveSuccessMessage}
                                        onDismiss={() => {
                                            this.setState({
                                                saveSuccess: false,
                                            });
                                        }}
                                    />
                                )}
                                {!!this.state.saveError && this.state.saveError.length > 0 && (
                                    <ErrorMessagebar
                                        errorList={this.state.saveError}
                                        onDismiss={() => {
                                            this.setState({
                                                saveError: [],
                                            });
                                        }}
                                        aboveSplitForm
                                    />
                                )}
                                {this.contentReview && this.renderContentReview()}
                                {this.renderRejectModal()}
                                {this.state.displayPreview ? this.renderPreview() : this.renderForm()}
                            </div>
                        </div>
                    </div>
                    <Dialog
                        hidden={this.state.hideDialog}
                        containerClassName="dialogWithPopupHeader"
                        onDismiss={this._closeDialog}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: localize("Meddelande"),
                            closeButtonAriaLabel: localize("Stang"),
                            subText: this.state.dialogMessage,
                        }}
                        modalProps={{
                            isBlocking: true,
                            allowTouchBodyScroll: true,
                        }}
                    >
                        <PopupHeader
                            text={localize("Meddelande")}
                            subText={this.state.dialogMessage}
                            onClose={this._closeDialog}
                        />
                        <DialogFooter>
                            <PrimaryButton theme={this.context} onClick={this._closeDialog} text={localize("Ok")} />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={!this.state.displayApplyTemplateDialog}
                        containerClassName="dialogWithPopupHeader"
                        onDismiss={() => {
                            this.setState({
                                displayApplyTemplateDialog: false,
                            });
                        }}
                        modalProps={{
                            isBlocking: true,
                            allowTouchBodyScroll: true,
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: localize("Meddelande"),
                            closeButtonAriaLabel: localize("Stang"),
                            subText: localize("ApplyLayoutMsg"),
                        }}
                    >
                        <PopupHeader
                            text={localize("Meddelande")}
                            subText={localize("ApplyLayoutMsg")}
                            onClose={() => {
                                this.setState({ displayApplyTemplateDialog: false });
                            }}
                        />
                        <DialogFooter>
                            <DefaultButton
                                text={localize("Nej")}
                                onClick={() => {
                                    this.setState({
                                        displayApplyTemplateDialog: false,
                                    });
                                }}
                            />
                            <PrimaryButton
                                theme={this.context}
                                onClick={() => {
                                    this.setState(
                                        {
                                            displayApplyTemplateDialog: false,
                                            isLoading: true,
                                        },
                                        () => {
                                            api.get("/api/pages/" + this.state.templateIdToApply)
                                                .then((response) => {
                                                    this.initPage({
                                                        ...response.data,
                                                        selectedCustomTemplate: this.state.templateIdToApply,
                                                        id: this.state.page.id,
                                                        menuParent: this.state.page.menuParent,
                                                        menuPosition: this.state.page.menuPosition,
                                                    });
                                                })
                                                .catch(this.catchError.bind(this));
                                        }
                                    );
                                }}
                                text={localize("Ja")}
                            />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={!this.state.displayDeleteDialog}
                        containerClassName="dialogWithPopupHeader"
                        onDismiss={() => {
                            this.setState({
                                displayDeleteDialog: false,
                            });
                        }}
                        modalProps={{
                            isBlocking: true,
                            allowTouchBodyScroll: true,
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: localize("Meddelande"),
                            closeButtonAriaLabel: localize("Stang"),
                            subText: localize("VillDuVerkligenTaBortX").replace(
                                "{{X}}",
                                this.state.isTemplate ? localize("Mallen").toLowerCase() : localize("Ubertype9_0_1")
                            ),
                        }}
                    >
                        <PopupHeader
                            text={localize("Meddelande")}
                            subText={localize("VillDuVerkligenTaBortX").replace(
                                "{{X}}",
                                this.state.isTemplate ? localize("Mallen").toLowerCase() : localize("Ubertype9_0_1")
                            )}
                            onClose={() => {
                                this.setState({ displayDeleteDialog: false });
                            }}
                        />
                        <DialogFooter>
                            <DefaultButton
                                theme={this.context}
                                onClick={() => {
                                    this.setState({ displayDeleteDialog: false });
                                }}
                                text={localize("Avbryt")}
                            />
                            <PrimaryButton
                                theme={this.context}
                                onClick={() => {
                                    this.setState(
                                        {
                                            displayDeleteDialog: false,
                                            isLoading: true,
                                        },
                                        () => {
                                            api.put("/api/pages/" + this.state.page.id + "/toggledelete").then(() => {
                                                if (this.state.isTemplate) {
                                                    this.props.history.goBack();
                                                } else {
                                                    this.setState({
                                                        isLoading: false,
                                                        page: {
                                                            ...this.state.page,
                                                            isDeleted: true,
                                                        },
                                                    });

                                                    fetchMenu(this.props.dispatch, this.props.currentUser);
                                                }
                                            });
                                        }
                                    );
                                }}
                                text={localize("Ok")}
                            />
                        </DialogFooter>
                    </Dialog>
                    {this.state.isFilePickerOpen && (
                        <FilePicker
                            onClose={() => {
                                this.setState({
                                    isFilePickerOpen: false,
                                });
                            }}
                            onSelect={(data, source) => {
                                let newAttachedFolders = [
                                    ...(this.state.page.attachedFolders ? this.state.page.attachedFolders : []),
                                ];

                                let newAttachedFiles = [
                                    ...(this.state.page.attachedFiles ? this.state.page.attachedFiles : []),
                                ];

                                let newAttachedExternalFiles = [
                                    ...(this.state.page.attachedExternalFiles
                                        ? this.state.page.attachedExternalFiles
                                        : []),
                                ];

                                let newCaneaFiles = [...(this.state.page.caneaFiles ? this.state.page.caneaFiles : [])];

                                for (let i of data) {
                                    if (
                                        source === SpintrTypes.FolderRootSource.Office365Public ||
                                        source === SpintrTypes.FolderRootSource.Office365Private
                                    ) {
                                        newAttachedExternalFiles.push({ ...i, source: source });
                                    } else if (source === SpintrTypes.FolderRootSource.Canea) {
                                        newCaneaFiles.push(i);
                                    } else {
                                        if (i.isDirectory) {
                                            newAttachedFolders.push(i);
                                            this.props.dispatch(fetchAttachedFolder(i, undefined));
                                        } else {
                                            newAttachedFiles.push(i.id);
                                            this.props.dispatch(fetchAttachedFile(i.id));
                                        }
                                    }
                                }

                                this.setState({
                                    page: {
                                        ...this.state.page,
                                        attachedFolders: newAttachedFolders,
                                        attachedFiles: newAttachedFiles,
                                        // attachedFilesAndFolders: [...newAttachedFiles, ...newAttachedFolders],
                                        attachedExternalFiles: newAttachedExternalFiles,
                                        caneaFiles: newCaneaFiles,
                                    },
                                });
                            }}
                            showFiles
                            selectFolders={true}
                            allowMultipleFiles={true}
                            sourcesToDisplay={[
                                SpintrTypes.FolderRootSource.Office365Public,
                                SpintrTypes.FolderRootSource.SpintrPublic,
                                ...(this.props.enableCanea ? [SpintrTypes.FolderRootSource.Canea] : []),
                            ]}
                            source={SpintrTypes.FolderRootSource.SpintrPublic}
                        />
                    )}
                </div>
            </ContentWithInfoPanel>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        instance: state.instance,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        enableExternalFiles: state.profile.active.office365Connected && state.instance.get("office365Enabled"),
        enableMediaflow: state.instance.get("enableMediaflow"),
        enableUnsplash: state.instance.get("enableUnsplash"),
        disableCommentsByDefault: state.instance.get("disableCommentsByDefault"),
        currentUser: state.profile.active,
        privileges: state.privileges.data,
        requiredTagCount: state.instance.get("requiredTagCount"),
        enableCanea: state.instance.get("caneaUrl") !== "",
        group: state.groups.group,
        apps: state.app,
        isSmallViewMode: state.ui.isSmallViewMode,
        attachedFiles: state.pages.attachedFiles,
        attachedFolders: state.pages.attachedFolders,
    };
};

export default withRouter(connect(mapStateToProps)(PageFormView));
